import React, { useState, useEffect } from "react"
import { CustomProvider, IconButton, Pagination, Table } from "rsuite"
import VisibleIcon from "@rsuite/icons/Visible"
import CheckRoundIcon from "@rsuite/icons/CheckRound"
import CloseIcon from "@rsuite/icons/Close"
import PageIcon from "@rsuite/icons/Page"
import { getUsers } from "store/actions"
import { connect, useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import moment from "moment"

const { Column, HeaderCell, Cell } = Table

function NewRequest(props) {
  const [limit, setLimit] = useState(50)
  const [page, setPage] = useState(1)
  const dispatch = useDispatch()
  const data = props?.data

  // console.log(data1);

  // const data = [
  //   { id: 1, name: "Test_RUPIFI_Level1", description: "Test_RUPIFI_Level1", email: "xyzabc123@gmail.com", contact: "9876543212", created_at: "2022-02-14 21:58:53" },
  //   { id: 2, name: "Testing", description: "Test_RUPIFI_Level1", email: "xyzabc123@gmail.com", contact: "9876543212", created_at: "2022-02-14 21:58:53" },
  // ]

  const handleChangeLimit = dataKey => {
    setPage(1)
    setLimit(dataKey)
  }

  useEffect(() => {
    const displayStart = limit * (page - 1)

    const requestPayload = {
      token: props.token,
      status: 0,
      page: page,
      iDisplayStart: displayStart,
      iDisplayLength: limit,
    }
    dispatch(getUsers(requestPayload))
  }, [page, limit])

  return (
    <div className="table-responsive">
      <Table
        height={290}
        data={data}
        wordWrap="break-word"
        rowHeight={46}
        loading={props.tableLoading}
      >
        <Column width={50} align="center">
          <HeaderCell>Sr.No.</HeaderCell>
          <Cell dataKey="id" />
        </Column>
        <Column width={120}>
          <HeaderCell>Name</HeaderCell>
          <Cell dataKey="username" />
        </Column>
        <Column width={150}>
          <HeaderCell>Organization</HeaderCell>
          <Cell dataKey="organization_name" />
        </Column>
        <Column width={250}>
          <HeaderCell>Email</HeaderCell>
          <Cell dataKey="email" />
        </Column>
        <Column width={110}>
          <HeaderCell>Contact</HeaderCell>
          <Cell dataKey="contact" />
        </Column>
        <Column width={150}>
          <HeaderCell>Created At</HeaderCell>
          <Cell>
            {rowdata => (
              <>
                {moment(rowdata.created_at).format("DD-MM-YYYY")}
              </>
            )}
          </Cell>
        </Column>
        <Column width={140} align="center">
          <HeaderCell>Action</HeaderCell>
          <Cell>
            {rowdata => (
              <>
                <IconButton
                  size="xs"
                  appearance="primary"
                  color="green"
                  icon={<CheckRoundIcon />}
                />
                &nbsp;
                <IconButton
                  size="xs"
                  appearance="primary"
                  color="red"
                  icon={<CloseIcon />}
                />
                &nbsp;
                <Link to={"/admin/user-details/" + btoa(rowdata.id)}>
                  <IconButton
                    size="xs"
                    appearance="primary"
                    color="orange"
                    icon={<VisibleIcon />}
                  />
                </Link>
                &nbsp;
                <Link to={"/admin/user-letterhead/" + btoa(rowdata.id)}>
                  <IconButton
                    size="xs"
                    appearance="primary"
                    color="violet"
                    icon={<PageIcon />}
                  />
                </Link>
              </>
            )}
          </Cell>
        </Column>
      </Table>
      <Pagination
        className="my-4"
        prev
        next
        first
        last
        ellipsis
        boundaryLinks
        maxButtons={5}
        size="xs"
        layout={["total", "-", "limit", "|", "pager", "skip"]}
        total={props.tableCount}
        limitOptions={[50, 100, 200]}
        limit={limit}
        activePage={page}
        onChangePage={setPage}
        onChangeLimit={handleChangeLimit}
      />
    </div>
  )
}

const mapStateToProps = state => ({
  data: state.Users.items,
  tableLoading: state.Users.loading,
  tableCount: state.Users.count,
  token: state.Login.token,
})

export default connect(mapStateToProps)(NewRequest)
