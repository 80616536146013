import React, { useState } from 'react'
import {
  Col,
  Row,
  Input,
  Label,
  Button,
} from "reactstrap"
import {
  DatePicker,
  SelectPicker,
} from "rsuite"
import { useSelector } from 'react-redux'
import { UserActionService } from 'Service/userActionService'
import { ShowNotification } from 'common/ShowNotification'
import { ApiCall } from 'common/ActionApiCall/ApiCall'
import { ShowSwal, ShowSwalForMultiAction } from 'common/ShowSwal'

export default function BulkForm(props) {
  // const userList = useSelector(state => state.AllOnChangeUser.item)
  const token = useSelector(state => state.Login.token)
  const [userList, setuserlist] = useState([])
  const [selecteduser, setSelecteduser] = useState("")
  const [noticeList, setNoticelist] = useState([])
  const [batchlist, setbatchlist] = useState([])
  const [bulkData, setBulkData] = useState({
    token: token,
  })
  const AllFetchUserBySearch = (e) => {
    const requestPayload = {
      token: token,
      search_text: e,
    }
    if (e.length >= 3) {
      UserActionService.getUserBySearch(requestPayload)
        .then(res => {
          if (res.data.response === "success") {
            const newArray = res.data.responseData.map(obj => ({
              label: obj.username || obj.organization_name,
              value: obj.id
            }));
            setuserlist(newArray)
          }
          else {
            setuserlist([])
          }
        })
        .catch((e) => {
          setuserlist([])
        })
    }
  }
  const handleBulkSendNotice = () => {
    bulkData?.mnid && (bulkData?.batchName || (bulkData?.rangeTo && bulkData?.rangeFrom)) && [
      ShowSwal(
        "Are you sure?",
        "You won't be able to revert this!",
        "warning",
        "Yes, Send it!",
        result => {
          if (result.isConfirmed) {
            ApiCall(bulkData, "user/send-bulk-notice", false, result => {
              if (result?.data?.response === "success") {
                ShowNotification(result?.data?.message, "success")
              } else {
                ShowNotification(result?.data?.message, "danger")
              }
            })
          }
        }
      )
    ]

  }



  const getNoticeList = (e) => {
    UserActionService.getNoticeByUserId(e)
      .then(res => {
        setNoticelist(res.data.response)
      })
  }

  const getBatchList = (data) => {
    UserActionService.getbatchNameByNoticeId(data)
      .then(res => {
        setbatchlist(res.data.responseData)
      })
  }


  return (
    <div>
      <Row>
        <Col md={4}>
          <Label>Select User:*</Label>
          <SelectPicker
            onSearch={(e) => AllFetchUserBySearch(e)}
            value={selecteduser}
            block
            className='selectMenu'
            data={userList}
            onChange={(e) => {
              setSelecteduser(e)
              getNoticeList(e)
            }

            }
          />
        </Col>
        <Col md={4}>
          <Label>Select Notice Type:*</Label>
          <Input

            type="select"
            disabled={!noticeList?.length > 0 ? true : false}
            defaultValue={""}
            value={bulkData?.mnid}
            onChange={(e) => {
              setBulkData({ ...bulkData, mnid: e.target.value })
              getBatchList({ token: token, notice_id: e.target.value })
            }}
          >
            <option value="">Select Notice Type</option>
            {
              noticeList?.map((opt) => {
                return (
                  <option value={opt.notice_id} key={opt.notice_id}>
                    {opt.notice_name}
                  </option>
                )
              })
            }
          </Input>
        </Col>

        <Col md={4}>
          <Label>Select Batch Name:</Label>
          <Input
            type="select"
            disabled={!batchlist?.length > 0 ? true : false}
            defaultValue={""}
            onChange={(e) => {
              setBulkData({ ...bulkData, batchName: e.target.value, })
            }}
          >
            <option value="">Select Batch Name</option>
            {
              batchlist?.map((opt, index) => {
                return (
                  <option value={opt.batchName} key={index}>
                    {opt.batchName}
                  </option>
                )
              })
            }
          </Input>
        </Col>


      </Row>
      <Row>
        <Col md={4}>

          <Label className="mt-2" for="rangeFrom">
            From Notice Id :*
          </Label>
          <Input type='text' disabled={bulkData?.batchName} placeholder='From ex. IN12-1234' value={bulkData?.batchName ? "" : bulkData?.rangeFrom} onChange={(e) => { setBulkData({ ...bulkData, rangeFrom: e.target.value }) }} />
        </Col>
        <Col md={4}>

          <Label className="mt-2" for="">
            To Notice Id :*
          </Label>
          <Input type='text' disabled={bulkData?.batchName} placeholder='To ex. IN12-2345' value={bulkData?.batchName ? "" : bulkData?.rangeTo} onChange={(e) => { setBulkData({ ...bulkData, rangeTo: e.target.value }) }} />
        </Col>
        <Col md={4}>

          <Label className="mt-2" for="">
            Except Notice Id :
          </Label>
          <Input type='text' disabled={bulkData?.batchName} placeholder='Except ex. IN12-23,IN12-34,IN12-45' value={bulkData?.batchName ? "" : bulkData?.rangeExcept} onChange={(e) => { setBulkData({ ...bulkData, rangeExcept: e.target.value }) }} />
        </Col>
      </Row>

      <Row>
        <Col md={4}>

          <Label className="mt-2" for="">
            Schedule Date :
          </Label>
          <DatePicker
            value={bulkData?.schedule_on}
            onChangeCalendarDate={(e) => { setBulkData({ ...bulkData, schedule_on: e }) }}
            onClean={() => { setBulkData({ ...bulkData, schedule_on: null }) }}
            format="dd-MM-yyyy HH:mm"
            block
          />
        </Col>
      </Row>
      <Col style={{ marginTop: "1em" }}>
        <Button color='primary' onClick={() => {
          handleBulkSendNotice()
        }}>Submit</Button>


      </Col>
    </div>

  )
}
