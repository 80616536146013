import React, { useState } from 'react'
import { FileUploader } from 'react-drag-drop-files'
import {
    Col,
    Row,
    Input,
    Label,
    Button,
  } from "reactstrap"
  import {
    SelectPicker,
  } from "rsuite"
 import { useSelector} from 'react-redux'
import { UserActionService } from 'Service/userActionService'
import { ShowNotification } from 'common/ShowNotification'
import { ApiCall } from 'common/ActionApiCall/ApiCall'
import { ShowSwalForMultiAction } from 'common/ShowSwal'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

export default function CsvUpload(props) {
  // const userList = useSelector(state => state.AllOnChangeUser.item)
  const token = useSelector(state => state.Login.token)
   const [userList,setuserlist] = useState([])
  const [selecteduser,setSelecteduser] = useState("")
  const [noticeList,setNoticelist] = useState([])
  const [letterhead,setletterhead] = useState([])
  const [loading,setloading] = useState(false)
  const history = useHistory()

  const [bulkData, setBulkData] = useState({
    on_behalf_of:null,
    token: token,
    noticeId:null,
    batchName: null,
    user_bulk_data: null,
    lhead: null,
    is_sig: 1,
    ref_id_check: 0,
    ref_check_msg:null
  })
  const AllFetchUserBySearch = (e) => {
    const requestPayload = {
      token: token,
      search_text: e,
    }
    if(e.length >= 3) {
       UserActionService.getUserBySearch(requestPayload)
       .then (res=>{
         if(res.data.response === "success"){
           const newArray = res.data.responseData.map(obj => ({
            label: obj.username || obj.organization_name,
            value: obj.id
        }));
        setuserlist(newArray)
         }
         else {
           setuserlist([])
         }
       })
       .catch((e)=>{
        setuserlist([])
       })
    }
  }

  const handleUploadBulkDataAction = () => {
    bulkData?.batchName &&
      bulkData?.lhead &&
      bulkData?.user_bulk_data &&
      bulkData?.is_sig &&
      ApiCall({...bulkData,on_behalf_of:selecteduser}, "user/upload-bulk-csv", false, result => {
        if (result?.data?.response === "success") {
          ShowSwalForMultiAction(
            result?.data?.message,
            "Please wait for sometime while cases are being generate.",
            "success",
            "CSV Report",
            "Ok",
            result => {
              if (result.isConfirmed) {
                history.push("/admin/report")
              }
            }
          )
        } else {
          ShowNotification(result?.data?.message, "danger")
        }
      })
  }
   
  const handleReferenceIdCheck = () => {
    bulkData?.batchName &&
      bulkData?.user_bulk_data && [
        setloading(true),
        ApiCall(bulkData, "user/checkReferenceId", false, result => {
          if (result?.data?.response === "success") {
            setBulkData({ ...bulkData, ref_check_msg: result?.data?.responseData })
            ShowNotification(result?.data?.responseData, "success")
          } else {
            setBulkData({ ...bulkData, ref_check_msg: ' ' })
            ShowNotification(result?.data?.responseData, "danger")
          }
          setloading(false)
        }),
      ]
  }

   const getNoticeList = (e) =>{
      UserActionService.getNoticeByUserId(e)
      .then(res=>{
        setNoticelist(res.data.response)
      })
   }
   const getletterheadList = (e) =>{
    UserActionService.getLetterHeadByUserId(e)
    .then(res=>{
      setletterhead(res.data.response)
    })
    .catch(e =>{
       setletterhead([])
    })
 }

  return (
    <>
    <Row>
    <Col md={4}>
              <Label>Select User:*</Label>
              <SelectPicker
                onSearch={(e) => AllFetchUserBySearch(e)}
                value={selecteduser}
                block
                className="mb-3"
                data={userList}
                onChange={(e)=>{setSelecteduser(e)   
                  getNoticeList(e)  
                  getletterheadList(e)
                }
                  
                }
              />
            </Col>
      <Col md={4}>
        <Label>Select Notice Type:*</Label>
        <Input 
   type="select"
    disabled = {!noticeList?.length > 0 ? true : false}
    defaultValue={""}
    onChange={(e)=>{
    setBulkData({...bulkData,  noticeId:e.target.value,})
    }}
  >
    <option value="">Select Notice Type</option>
  {
    noticeList?.map((opt)=>{
      return (
        <option  value={opt.notice_id} key={opt.notice_id}>
       {opt.notice_name}
        </option>
      )
    })
  }
  </Input>
      </Col>
      <Col md={4}>
<Label for="letterhead">
Select LetterHead:*
</Label>
  <Input 
   type="select"
   defaultValue={""}
   disabled = {!letterhead?.length > 0 ? true : false}
   onChange={(e)=>{
    setBulkData({...bulkData,lhead:e.target.value,})
    }}
  >
    <option value="">Select Letter Head</option>
  {
    letterhead?.map((opt)=>{
      return (
        <option  value={opt.id} key={opt.id}>
       {opt.head_name}
        </option>
      )
    })
  }
  </Input>

</Col>
      
    
      </Row>
                <Row>
                <Col md={4}>
                <Label className="mt-2" for="letterhead">
                  Upload Csv:*
                </Label>
                <FileUploader
                  name="uploadCsv"
                  types={["csv"]}
                  onTypeError={e =>
                    e && setBulkData({ ...bulkData, user_bulk_data: null })
                  }
                  handleChange={e => 
                    {
                      setBulkData({ ...bulkData, user_bulk_data: e })
                    }
                   
                  }
                /> 
                  {
                     bulkData.user_bulk_data &&   <p>{bulkData.user_bulk_data.name}</p>
                  }
                   
                    
                     </Col>
                      
                <Col md={4}>

<Label className="mt-2" for="letterhead">
Enter Batch Name :*
</Label>
<Input type='text' onChange={(e)=>{setBulkData({ ...bulkData, batchName: e.target.value })}}/>
</Col>

    
<Col md={4}>
<Label className="mt-2" for="letterhead">
Want To Use Signature Of Above Letter Head ?
</Label>
  <Input 
   type="select"
   onChange={(e)=>{setBulkData({ ...bulkData, is_sig: e.target.value })}}
  >
  <option value="1">
        Yes
      </option>
      <option value="0">
        No
      </option>
  </Input>

</Col>
                </Row>
         
             <Row>
             <Col md={4}>
<Label className="mt-2" for="letterhead">
Want To Check For Reference Id ?
</Label>
  <Input 
   type="select"
    onChange={(e)=>{
      setBulkData({ ...bulkData, ref_check_msg: e.target.value })
    }}
    defaultValue={0}
  >
    <option value={1}>
        Yes
      </option>
      <option value={0}>
        No
      </option>
  </Input>

</Col> 
             </Row>
             <Col style={{marginTop:"1em"}}>

              {
                  bulkData?.ref_check_msg == 1 ?  <Button color='primary' onClick={()=>{
                    handleReferenceIdCheck()
                  }}>Check</Button> :  <Button  color='primary' onClick={()=>{
                    console.log(bulkData)
                    handleUploadBulkDataAction()
                  }}>Submit</Button>
              }

 </Col>
 </>
  
  )
}
