import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { connect } from "react-redux"
import { CustomProvider } from "rsuite"
import { setBreadcrumbItems } from "../../../store/actions"
import RejectedTable from "./RejectedTable"
import NewRejectedTable from "./NewRejectedTable"
import { useParams } from "react-router-dom"
import FilterMenu from "../FilterMenu"

const UserRejected = props => {
  const params = useParams()
  const [openFilterMenu, setOpenFilterMenu] = useState(false)
  const [filter, setFilter] = useState({
    batch: "",
    notesAdded: "",
    replyAdded: "",
    undeliveredWhatsapp: 0,
    undeliveredEmail: 0,
    undeliveredSms: 0,
    case_ref_id: "",
    submit: false,
    filterFrom: "",
    filterTo: "",
  })
  const breadcrumbItems = params?.userId
    ? [
        { title: "Incase360", link: "#" },
        { title: "Sub User", link: "/user/subuser" },
        {
          title: "Sub User Notice List",
          link: "/user/subuser/noticelist/" + params?.userId,
        },
        { title: "Sub User Rejected Notice Details", link: "#" },
      ]
    : [
        { title: "Incase360", link: "#" },
        { title: "Notice List", link: "/user/noticelist" },
        { title: "Rejected Notice Details", link: "#" },
      ]

  const fromToDate = [
    {
      id: 1,
      name: "rangeDateFrom",
      placeholder: "From",
      onChange: e => setFilter({ ...filter, filterFrom: e }),
    },
    {
      id: 2,
      name: "rangeDateTo",
      placeholder: "To",
      min: filter?.filterFrom,
      onChange: e => setFilter({ ...filter, filterTo: e }),
    },
  ]

  useEffect(() => {
    props.setBreadcrumbItems(
      `${
        params?.userId ? "Sub User" : ""
      } Rejected Notice Details - ${props?.data?.noticeName?.replace(
        "notice_",
        ""
      )}`,
      breadcrumbItems
    )
  })

  return (
    <React.Fragment>
      <MetaTags>
        <title>Notice Details - User | Incase360</title>
      </MetaTags>
      <CustomProvider theme="dark">
        {!params?.userId && (
          <FilterMenu
            openFilterMenu={openFilterMenu}
            setOpenFilterMenu={setOpenFilterMenu}
            state={filter}
            setState={setFilter}
            mnid={params?.id}
            tab="reject"
            fromToDate={fromToDate}
          />
        )}
        <NewRejectedTable filter={filter} />
      </CustomProvider>
    </React.Fragment>
  )
}

const mapStateToProps = state => ({
  data: state.DraftNotice.items,
})

export default connect(mapStateToProps, { setBreadcrumbItems })(UserRejected)
