import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { connect, useDispatch } from "react-redux"
import { useParams } from "react-router-dom"

import {
  getExistingNoticDetailsById,
  setBreadcrumbItems,
} from "../../../store/actions"
import NoticeForm from "./NoticeForm"

const EditNotice = props => {
  const [request, setRequest] = useState([])
  const dispatch = useDispatch()
  const params = useParams()

  const breadcrumbItems = [
    { title: "Incase360", link: "#" },
    { title: "Existing Notice", link: "/admin/existingnotice" },
    { title: "Edit Notice", link: "#" },
  ]

  useEffect(() => {
    props.setBreadcrumbItems("Edit Notice", breadcrumbItems)
  })

  useEffect(() => {
    const requestPayload = {
      token: props?.token,
      noticeId: atob(params.id),
    }
    dispatch(
      getExistingNoticDetailsById(requestPayload, result => {
        const getRequestData = {
          notice_id: result?.data?.responseData?.notice_id,
          notice_type: result?.data?.responseData?.notice_type,
          draft_notice_html: result?.data?.responseData?.draft_notice_html,
          willLinkExpire: result?.data?.responseData?.willLinkExpire,
          send_as: result?.data?.responseData?.send_as,
          token: props?.token,
          notice_desc: result?.data?.responseData?.notice_desc,
          bulk_csv: null,
          notice_bulk: result?.data?.responseData?.notice_bulk,
          autoGeneratePdf: result?.data?.responseData?.autoGeneratePdf,
          canvaSubtitle: result?.data?.responseData?.canvaSubtitle,
          enterpriseAbbr: result?.data?.responseData?.enterprise_abbr,
          emailEnabled: result?.data?.responseData?.email_enabled,
          smsEnabled: result?.data?.responseData?.sms_enabled,
          whatsappEnabled: result?.data?.responseData?.whatsapp_enabled,
          rpadEnabled: result?.data?.responseData?.rpad_enabled,
        }
        setRequest(getRequestData)
      })
    )
  }, [])

  return (
    <React.Fragment>
      <MetaTags>
        <title>Edit Notice - Admin | Incase360</title>
      </MetaTags>

      {request?.draft_notice_html && (
        <NoticeForm
          existData={props?.rowCount !== null}
          request={request}
          setRequest={setRequest}
          loading={props?.loading}
        />
      )}
    </React.Fragment>
  )
}

const mapStateToProps = state => ({
  data: state.ExistingNoticeDetailsById.items,
  loading: state.ExistingNoticeDetailsById.loading,
  rowCount: state.ExistingNoticeDetailsById.rowCount,
  token: state.Login.token,
})

export default connect(mapStateToProps, { setBreadcrumbItems })(EditNotice)
