const localurl = "http://localhost/incase360"
const testingurl = "https://testapi.incase360.com"
const prodUrl = "https://api.incase360.com"


const actualurl = testingurl

export const ApiRoute = `${actualurl}/api/`
//export const ApiRoute = "https://api.incase360.com/api/"

export const RouteWithoutApi = `${actualurl}/`
//export const RouteWithoutApi = "https://api.incase360.com/"

export const RouteForLetterHead =`${actualurl}/assets/upload/multiLetterHead/`
//export const RouteForLetterHead = "https://api.incase360.com/assets/upload/multiLetterHead/" 

export const RouteForShow = `${actualurl}/assets/upload/`
//export const RouteForShow = "https://api.incase360.com/assets/upload/"

