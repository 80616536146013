import React, { useState, useEffect } from "react"
import {
  Button,
  Checkbox,
  CustomProvider,
  IconButton,
  Pagination,
} from "rsuite"
import VisibleIcon from "@rsuite/icons/Visible"
import EditIcon from "@rsuite/icons/Edit"
import CloseIcon from "@rsuite/icons/Close"
import AddOutlineIcon from "@rsuite/icons/AddOutline"
import EventDetailIcon from "@rsuite/icons/EventDetail"
import DetailIcon from "@rsuite/icons/Detail"
import { Link, useParams } from "react-router-dom"
import { getDataNotice, getUserLetterhead } from "store/actions"
import { connect, useDispatch } from "react-redux"
import WhisperTooltip from "components/Common/Whisper/WhisperTooltip"
import useWindowDimensions from "common/useWindowDimensions"
import { DownloadApiCall } from "common/ActionApiCall/DownloadApiCall"
import { Table } from "reactstrap"
import Loader from "common/Loader"

function NewDraftTable(props) {
  const [limit, setLimit] = useState(50)
  const [page, setPage] = useState(1)
  const dispatch = useDispatch()
  const params = useParams()
  let checked = false
  let indeterminate = false
  const { height, width } = useWindowDimensions()

  const data = props?.data?.resultData

  // console.log(data);

  const handleChangeLimit = dataKey => {
    setPage(1)
    setLimit(dataKey)
  }

  if (data?.length !== 0) {
    if (props.checkedKeys?.length === data?.length) {
      checked = true
      props?.setOpenSubMenu(true)
    } else if (props?.checkedKeys?.length === 0) {
      checked = false
      props?.setOpenSubMenu(false)
    } else if (
      props?.checkedKeys?.length > 0 &&
      props?.checkedKeys?.length < data?.length
    ) {
      indeterminate = true
      props?.setOpenSubMenu(true)
    }
  }

  const handleCheckAll = (value, checked) => {
    const keys = checked ? data?.map(item => item.Id) : []
    props?.setCheckedKeys(keys)
    props?.setOpenSubMenu(checked)
  }

  const handleCheck = (value, checked) => {
    const keys = checked
      ? [...props?.checkedKeys, value]
      : props?.checkedKeys?.filter(item => item !== value)
    props?.setCheckedKeys(keys)
    checked && props?.setOpenSubMenu(checked)
  }

  const downloadClickNoticePdf = file => {
    const request = {
      token: props?.token,
      folder: "userGnrtdNotice",
      filename: file,
    }
    DownloadApiCall(request, "download/single-download", "_blank")
  }

  useEffect(() => {
    const displayStart = limit * (page - 1)

    const requestPayload = {
      data_type: "draft",
      notice_id: params.id,
      user_id: params?.userId ? atob(params?.userId) : "",
      token: props.token,
      page: page,
      iDisplayStart: displayStart,
      iDisplayLength: limit,
      batch: props?.filter?.batch,
      case_ref_id: props?.filter?.case_ref_id,
      filterFrom: props?.filter?.filterFrom,
      filterTo: props?.filter?.filterTo,
    }
    dispatch(getDataNotice(requestPayload))
  }, [page, limit, props?.action, props?.filter?.submit])

  return (
    <div className="table-responsive" style={{ fontSize: "0.9em" }}>
      <div style={{ height: height - 300, overflow: "scroll" }}>
        <Table size="sm">
          <thead

          // style={{
          //   position: "sticky",
          //   top: "0px",
          //   background: "#2a3142",
          //   zIndex: 5,
          // }}
          >
            <tr>
              <th>
                <Checkbox
                  inline
                  checked={checked}
                  indeterminate={indeterminate}
                  onChange={handleCheckAll}
                />
              </th>
              <th>Reference ID</th>
              <th>Notice ID</th>
              <th>Date of Generation</th>
              <th>Batch</th>
              <th>Details</th>
              {!params?.userId && <th>Action</th>}
            </tr>
          </thead>

          <tbody>
            {/* <div className="d-flex align-items-center justify-content-center">
            </div> */}
            {props?.tableLoading ? (
              <tr>
                <td colSpan={7} className="text-center">
                  <div className="mt-4">
                    <Loader />
                  </div>
                </td>
              </tr>
            ) : data?.length !== 0 ? (
              data?.map((rowdata, index) => {
                return (
                  <tr key={index}>
                    <td>
                      {page === 1 ? index + 1 : limit * (page - 1) + index + 1}
                      <Checkbox
                        value={rowdata.Id}
                        inline
                        onChange={handleCheck}
                        checked={props?.checkedKeys?.some(
                          item => item === rowdata.Id
                        )}
                      />
                    </td>
                    <td>{rowdata.Reference_ID}</td>
                    <td>{rowdata.notice_unq_id}</td>
                    <td>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: rowdata.date_of_generation,
                        }}
                      />
                    </td>
                    <td>{rowdata.batchName}</td>
                    <td>
                      <WhisperTooltip
                        placement="top"
                        trigger="hover"
                        tooltipMsg="View Party Details"
                      >
                        <Link
                          to={{
                            pathname: "/user/party-details/" + btoa(rowdata.Id),
                            state: { prevPath: location.pathname },
                          }}
                        >
                          <IconButton
                            size="xs"
                            appearance="primary"
                            color="violet"
                            icon={<DetailIcon />}
                          />
                        </Link>
                      </WhisperTooltip>
                    </td>
                    {!params?.userId && (
                      <td>
                        <WhisperTooltip
                          placement="top"
                          trigger="hover"
                          tooltipMsg="View Notice PDF"
                        >
                          <IconButton
                            size="xs"
                            appearance="primary"
                            color="orange"
                            icon={<VisibleIcon />}
                            onClick={() =>
                              downloadClickNoticePdf(rowdata?.user_notice_path)
                            }
                          />
                        </WhisperTooltip>
                        &nbsp;
                        <WhisperTooltip
                          placement="top"
                          trigger="hover"
                          tooltipMsg="Edit Notice"
                        >
                          <Link
                            to={{
                              pathname:
                                "/user/edit-Notice/" +
                                rowdata.Id +
                                "/" +
                                params?.id,
                              state: { prevPath: location.pathname },
                            }}
                          >
                            <IconButton
                              size="xs"
                              appearance="primary"
                              color="blue"
                              icon={<EditIcon />}
                            />
                          </Link>
                        </WhisperTooltip>
                      </td>
                    )}
                  </tr>
                )
              })
            ) : (
              <tr>
                <td colSpan={7} className="text-center">
                  <div className="mt-4">No Data Found</div>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
      <Pagination
        className="my-4"
        prev
        next
        first
        last
        ellipsis
        boundaryLinks
        maxButtons={5}
        size="xs"
        layout={["total", "-", "limit", "|", "pager", "skip"]}
        total={props?.data?.draftCount}
        limitOptions={[50, 100, 500, 1000, 5000, 10000]}
        limit={limit}
        activePage={page}
        onChangePage={setPage}
        onChangeLimit={handleChangeLimit}
      />
    </div>
  )
}

const mapStateToProps = state => ({
  data: state.DraftNotice.items,
  tableLoading: state.DraftNotice.loading,
  token: state.Login.token,
})

export default connect(mapStateToProps)(NewDraftTable)
