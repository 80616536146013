import React from "react"
import { Input, InputGroup } from "rsuite"
import SearchIcon from "@rsuite/icons/Search"

function SearchInput(props) {
  const handleKeyPress = e => {
    if (e.key === "Enter") {
      props?.setSearch({
        ...props?.search,
        submit: !props?.search?.submit,
      })
    }
  }

  return (
    <div>
      <InputGroup className="mb-2" style={{ width: 200 }}>
        <Input
          placeholder={props?.placeholder}
          defaultValue={props?.search?.value || ""}
          onChange={e => props?.setSearch({ ...props?.search, value: e })}
          onKeyPress={e => handleKeyPress(e)}
          type="text"
        />
        <InputGroup.Button
          onClick={() =>
            props?.setSearch({
              ...props?.search,
              submit: !props?.search?.submit,
            })
          }
        >
          <SearchIcon />
        </InputGroup.Button>
      </InputGroup>
    </div>
  )
}

export default SearchInput
