//
import React, { useEffect, useState } from "react"
import { CKEditor } from "ckeditor4-react"
// import SunEditor from "suneditor-react"
// import "suneditor/dist/css/suneditor.min.css" // Import Sun Editor's CSS File
import "./index.scss"

const Editor = props => {
  // const [content, setContent] = useState(props?.request)
  const [variable, setVariable] = useState([])

  useEffect(() => {
    if (typeof props?.request?.draft_notice_html !== "undefined") {
      const splitValue = props?.request?.draft_notice_html?.split("--")
      // console.log(splitValue)
      setVariable([])
      splitValue?.map((value, key) => {
        if (key % 2 !== 0) {
          // console.log(key)
          setVariable(prev => [...prev, value])
        }
      })
    }
  }, [props?.request])

  const handleOnChange = ({ editor }) => {
    // Will be triggered only once, when editor is ready for interaction.
    // editor.setData(props?.defaultValue)
    props?.setRequest(prev => ({
      ...prev,
      draft_notice_html: editor.getData(),
    }))
  }

  return (
    <div className="row">
      <div className={props?.editType === true ? "col-md-9 col-sm-12" : ""}>
        <CKEditor
          name="editor1"
          type="classic"
          initData={props?.request?.draft_notice_html}
          editorUrl="https://cdn.ckeditor.com/4.16.0/full/ckeditor.js"
          onChange={handleOnChange}
        />
        {/* <SunEditor
          // onSetToolbarButtons={}
          setContents={props?.request?.draft_notice_html || ""}
          height="35em"
          onChange={e =>
            props.setRequest(prev => ({ ...prev, draft_notice_html: e }))
          }
        /> */}
      </div>
      {props?.editType === true && (
        <div className="col-md-3 col-sm-12 show-variable">
          <h4>Variable</h4>
          <div className="contentVariable">
            {/*  */}
            {variable.map((value, index) => {
              return <p key={index}>{value}</p>
            })}
          </div>
        </div>
      )}
    </div>
  )
}
export default Editor
