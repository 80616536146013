import { TabBar } from "common/TabBar/TabBar"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { connect } from "react-redux"
import { Row, Col } from "reactstrap"
import { CustomProvider } from "rsuite"

// Pages Components
// import MonthlyEarnings from "./montly-earnings";
// import EmailSent from "./email-sent";
// import MonthlyEarnings2 from "./montly-earnings2";
// import Inbox from "./inbox";
// import RecentActivity from "./recent-activity";
// import WidgetUser from "./widget-user";
// import YearlySales from "./yearly-sales";
// import LatestTransactions from "./latest-transactions";
// import LatestOrders from "./latest-orders";

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../../store/actions"
import ApprovedUsers from "./ApprovedUsers"
import NewRequest from "./NewRequest"
import UnapprovedUsers from "./UnapprovedUsers"

const ManageUsers = props => {
  const [active, setActive] = useState("NewRequest")

  const tabbardata = [
    {
      eventkey: "NewRequest",
      lable: "New Request",
    },
    { eventkey: "approvedUsers", lable: "Approved Users" },
    { eventkey: "unapprovedUsers", lable: "Unapproved Users" },
  ]

  const breadcrumbItems = [
    { title: "Incase360", link: "#" },
    { title: "Manage Users", link: "#" },
  ]

  useEffect(() => {
    props.setBreadcrumbItems("Manage Users", breadcrumbItems)
  })

  return (
    <React.Fragment>
      <MetaTags>
        <title>Manage Users - Admin | Incase360</title>
      </MetaTags>

      <CustomProvider theme="dark">
        <TabBar
          style={{textAlign: "center"}}
          appearance="tabs"
          active={active}
          onSelect={setActive}
          panel={tabbardata}
        />
        {active === "NewRequest" && (
          <>
            <NewRequest />
          </>
        )}
        {active === "approvedUsers" && (
          <>
            <ApprovedUsers />
          </>
        )}
        {active === "unapprovedUsers" && (
          <>
            <UnapprovedUsers />
          </>
        )}
      </CustomProvider>
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(ManageUsers)
