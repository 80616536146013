import axios from "axios"
import { ApiRoute } from "common/ApiRoute"
import { ShowNotification } from "common/ShowNotification"
import { ShowSwal } from "common/ShowSwal"

const api_url = ApiRoute

export const DownloadApiCall = (requestPayload, url, target) => {
  const headers = {
    "Content-Type": "application/json",
  }

  let formData = new FormData()
  Object.entries(requestPayload).map(([key, value]) => {
    formData.append(key, value)
  })

  //   $.ajax({
  //     method: "POST",
  //     url: api_url + url,
  //     data: {
  //         id: id,
  //         folder: folder,
  //         filename: filename,
  //     },
  //     xhrFields: {
  //         responseType: "blob", // to avoid binary data being mangled on charset conversion
  //     },

  //   })
  ShowSwal("Download", "Please wait file is getting download...", "info", "Ok")
  axios({
    url: api_url + url,
    method: "POST",
    data: formData,
    // responseType: "blob", // important
  }).then(function (res) {
    if (res?.data?.response == "success") {
      const link = document.createElement("a")
      // link.target = "_blank"
      link.target = target
      link.href = res?.data?.url
      document.body.appendChild(link)
      link.click()
      link.parentNode.removeChild(link)
      ShowSwal("", "Successfully Download!", "success", "Ok")
    } else {
      ShowSwal("", "File Not Found!", "error", "Ok")
      ShowNotification(
        res?.data?.message ? res?.data?.message : "File Not Found!",
        "danger"
      )
    }
    // var filename = ""
    // var disposition = xhr.getResponseHeader("Content-Disposition")
    // if (disposition && disposition.indexOf("attachment") !== -1) {
    //   var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
    //   var matches = filenameRegex.exec(disposition)
    //   if (matches != null && matches[1])
    //     filename = matches[1].replace(/['"]/g, "")
    // }
    // const url = window.URL.createObjectURL(new Blob([response.data]));
  })
  // .catch(error => {
  //   console.log("extra")
  //   ShowNotification("File Not Found!", "danger")
  // })

  //   axios
  //     .post(api_url + url, formData, {
  //       headers: headers,
  //     })
  //     .then(result => {
  //       callback(result)
  //     })
  //     .catch(error => {
  //       callback(error.response)
  //     })
}
