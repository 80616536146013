import React, { useEffect, useState, useRef } from "react"
import {
  Button,
  Checkbox,
  Col,
  Grid,
  IconButton,
  Row,
  SelectPicker,
} from "rsuite"
import SettingHorizontalIcon from "@rsuite/icons/SettingHorizontal"
import TagFilterIcon from "@rsuite/icons/TagFilter"
import CloseOutlineIcon from "@rsuite/icons/CloseOutline"
import "./filter.scss"
import { Link } from "react-router-dom"
import { Input } from "rsuite"
import WhisperTooltip from "components/Common/Whisper/WhisperTooltip"
import FormGroup from "components/Common/FormGroup"
import { ApiCall } from "common/ActionApiCall/ApiCall"
import { connect } from "react-redux"
import { Input as RsInput } from "reactstrap"

function FilterMenu(props) {
  const [batchData, setBatchData] = useState()
  const ref = useRef()

  const handleCheckWp = (value, checked) => {
    props?.setState({
      ...props?.state,
      undeliveredWhatsapp: checked ? 1 : 0,
    })
  }

  const handleCheckEmail = (value, checked) => {
    props?.setState({
      ...props?.state,
      undeliveredEmail: checked ? 1 : 0,
    })
  }

  const handleCheckSMS = (value, checked) => {
    props?.setState({
      ...props?.state,
      undeliveredSms: checked ? 1 : 0,
    })
  }

  useEffect(() => {
    const request = {
      token: props?.token,
      mnid: props?.mnid,
      tab: props?.tab,
    }
    ApiCall(request, "user/batch-name", false, result => {
      // console.log(result);
      const data = result?.data?.message?.map(item => ({
        label: item["batchName"],
        value: item["batchName"],
      }))
      setBatchData(data)
    })

    const closeDiv = e => {
      console.log(ref.current.contains(e.target))
      if (ref && !ref.current.contains(e.target)) {
        props?.setOpenFilterMenu(false)
      }
    }
    document.body.addEventListener("click", closeDiv)
    return () => document.body.removeEventListener("click", closeDiv)
  }, [])

  return (
    <>
      <div ref={ref}>
        <div
          className="subFilter"
          style={{
            // display: `${openSubMenu ? "block" : "none"}`
            opacity: `${!props?.openFilterMenu ? 0 : 1}`,
            transition: "all 1s",
            visibility: `${!props?.openFilterMenu ? "hidden" : "visible"}`,
          }}
        >
          <Grid fluid>
            <Row className="show-grid">
              <div>
                {props?.fromToDate &&
                  props?.fromToDate?.map((value, index) => {
                    return (
                      <div key={index}>
                        <Col
                          lg={12}
                          md={12}
                          sm={24}
                          xs={24}
                          className="mt-3 mb-2"
                        >
                          <span>{value?.placeholder}</span>
                          <Input
                            type="date"
                            name={value?.name}
                            id={value?.name}
                            min={value?.min}
                            placeholder={value?.placeholder}
                            onChange={value?.onChange}
                            block
                          />
                        </Col>
                      </div>
                    )
                  })}
                <span>Reference / Case Id:</span>
                <Input
                  className="mb-2"
                  name="id"
                  type="text"
                  defaultValue={props?.state?.case_ref_id || ""}
                  onChange={e =>
                    props?.setState({
                      ...props?.state,
                      case_ref_id: e,
                    })
                  }
                />
                <span>Batch Name:</span>
                {/* <SelectPicker
                  name="batch"
                  className="mb-2"
                  block
                  data={batchData}
                  defaultValue={props?.state?.batch}
                  onChange={e => props?.setState({ ...props?.state, batch: e })}
                /> */}
                <RsInput
                  className="mb-2"
                  style={{
                    border: "1px solid #3c3f43",
                    background: "#1a1d24",
                  }}
                  name="batch"
                  type="select"
                  defaultValue={props?.state?.batch || null}
                  onChange={e =>
                    props?.setState({
                      ...props?.state,
                      batch: e?.target?.value,
                    })
                  }
                >
                  <option value="">Select</option>
                  {batchData?.map((item, index) => {
                    return (
                      <option key={index} value={item?.value}>
                        {item?.label}
                      </option>
                    )
                  })}
                </RsInput>

                {!props?.option && (
                  <>
                    <span>Note Added:</span>
                    <RsInput
                      className="mb-2"
                      style={{
                        border: "1px solid #3c3f43",
                        background: "#1a1d24",
                      }}
                      name="notes"
                      type="select"
                      defaultValue={props?.state?.notesAdded || null}
                      onChange={e =>
                        props?.setState({
                          ...props?.state,
                          notesAdded: e?.target?.value,
                        })
                      }
                    >
                      <option value="">Select</option>
                      <option value="1">Not Added</option>
                      <option value="2">Added</option>
                    </RsInput>
                    {/* <SelectPicker
                      name="notes"
                      className="mb-2"
                      block
                      data={[
                        { label: "Not Added", value: 1 },
                        { label: "Added", value: 2 },
                      ]}
                      defaultValue={props?.state?.notesAdded || null}
                      onChange={e =>
                        props?.setState({ ...props?.state, notesAdded: e })
                      }
                    /> */}

                    <span>Reply Added:</span>
                    <RsInput
                      className="mb-2"
                      style={{
                        border: "1px solid #3c3f43",
                        background: "#1a1d24",
                      }}
                      name="reply"
                      type="select"
                      defaultValue={props?.state?.replyAdded || null}
                      onChange={e =>
                        props?.setState({
                          ...props?.state,
                          replyAdded: e?.target?.value,
                        })
                      }
                    >
                      <option value="">Select</option>
                      <option value="1">Not Added</option>
                      <option value="2">Added</option>
                    </RsInput>
                    {/* <SelectPicker
                      name="reply"
                      className="mb-2"
                      block
                      data={[
                        { label: "Not Added", value: 1 },
                        { label: "Added", value: 2 },
                      ]}
                      defaultValue={props?.state?.replyAdded || null}
                      onChange={e =>
                        props?.setState({ ...props?.state, replyAdded: e })
                      }
                    /> */}

                    <label>Show Undelivered: </label>
                    <br />
                    <Checkbox
                      onChange={handleCheckWp}
                      checked={props?.state?.undeliveredWhatsapp == 1}
                    >
                      WhatsApp
                    </Checkbox>
                    <Checkbox
                      onChange={handleCheckEmail}
                      checked={props?.state?.undeliveredEmail == 1}
                    >
                      Email
                    </Checkbox>
                    <Checkbox
                      onChange={handleCheckSMS}
                      checked={props?.state?.undeliveredSms == 1}
                    >
                      SMS
                    </Checkbox>
                    <br />
                  </>
                )}
                <div className="text-end">
                  <Button
                    appearance="primary"
                    onClick={() => {
                      props?.setState({
                        ...props?.state,
                        submit: !props?.state?.submit,
                      })
                    }}
                  >
                    Submit
                  </Button>
                  <Button
                    appearance="link"
                    onClick={() => {
                      ;[
                        Array.from(
                          document.querySelectorAll(["input", "select"])
                        ).forEach(input => (input.value = "")),
                        Array.from(
                          document.querySelectorAll(".rs-picker-toggle-clean")
                        ).forEach(input => input.click()),
                        props?.setState({
                          ...props?.state,
                          replyAdded: null,
                          notesAdded: null,
                          batch: null,
                          case_ref_id: "",
                          filterFrom: "",
                          filterTo: "",
                          undeliveredWhatsapp: 0,
                          undeliveredEmail: 0,
                          undeliveredSms: 0,
                        }),
                      ]
                    }}
                  >
                    Clear All
                  </Button>
                </div>
              </div>
            </Row>
          </Grid>
        </div>
        <div className="moreFilter">
          <WhisperTooltip placement="left" trigger="hover" tooltipMsg="Filter">
            {/* <IconButton
              onClick={() => {
                props?.setOpenFilterMenu(!props?.openFilterMenu)
              }}
              size="sm"
              appearance="primary"
              color={props?.openFilterMenu ? "red" : "green"}
              icon={
                props?.openFilterMenu ? <CloseOutlineIcon /> : <TagFilterIcon />
              }
            >
              {!props?.openFilterMenu ? "Filter" : "Close"}
            </IconButton> */}
            <Button
              startIcon={
                props?.openFilterMenu ? <CloseOutlineIcon /> : <TagFilterIcon />
              }
              onClick={() => {
                props?.setOpenFilterMenu(!props?.openFilterMenu)
              }}
              size="sm"
              appearance="primary"
              color={props?.openFilterMenu ? "red" : "green"}
            >
              {!props?.openFilterMenu ? <>Filter</> : <>Close</>}
            </Button>
          </WhisperTooltip>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = state => ({
  token: state.Login.token,
})

export default connect(mapStateToProps)(FilterMenu)
