const { default: axios } = require("axios")
 import { ApiRoute, RouteWithoutApi } from "common/ApiRoute"
 const UserActionService = {
    getUserBySearch : function (data){
        return axios
        .post(ApiRoute+`onChangeUserSelect`,data)
    },
    getNoticeByUserId:function (id){
         const payload = new FormData()
         payload.append('userId',id)
      
       return axios
        .post(`${ApiRoute}getAssignedNoticeByUserId`,payload)
    },
    getLetterHeadByUserId:function (id){
        const payload = new FormData()
        payload.append('userId',id)
     
      return axios
       .post(`${ApiRoute}letterheadByUserId`,payload)
   },
   getbatchNameByNoticeId:function(data){
    const payload = new FormData()
    payload.append('token',data.token)
    payload.append('notice_id',data.notice_id)
 
  return axios
   .post(`${RouteWithoutApi}getBatchByNoticeId`,payload)
   }


}
export {UserActionService}