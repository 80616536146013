import React, { useState, useEffect } from "react"
import { CustomProvider, IconButton, Pagination, Table } from "rsuite"
import VisibleIcon from "@rsuite/icons/Visible"
import CheckRoundIcon from "@rsuite/icons/CheckRound"
import PageIcon from "@rsuite/icons/Page"
import { getUsers } from "store/actions"
import { useDispatch, connect } from "react-redux"
import { Link } from "react-router-dom"
import moment from "moment"

const { Column, HeaderCell, Cell } = Table

function ApprovedUsers(props) {
  const [limit, setLimit] = useState(50)
  const [page, setPage] = useState(1)
  const dispatch = useDispatch()
  const data = props?.data

  const handleChangeLimit = dataKey => {
    setPage(1)
    setLimit(dataKey)
  }

  useEffect(() => {
    const displayStart = limit * (page - 1)

    const requestPayload = {
      token: props.token,
      status: 1,
      page: page,
      iDisplayStart: displayStart,
      iDisplayLength: limit,
    }
    dispatch(getUsers(requestPayload))
  }, [page, limit])

  return (
    <div className="table-responsive">
      <Table
        height={290}
        data={data}
        wordWrap="break-word"
        rowHeight={46}
        loading={props.tableLoading}
      >
        <Column width={50} align="center">
          <HeaderCell>Sr.No.</HeaderCell>
          <Cell dataKey="id" />
        </Column>
        <Column width={120}>
          <HeaderCell>Name</HeaderCell>
          <Cell dataKey="username" />
        </Column>
        <Column width={120}>
          <HeaderCell>Organization</HeaderCell>
          <Cell dataKey="organization_name" />
        </Column>
        <Column width={150}>
          <HeaderCell>Email</HeaderCell>
          <Cell dataKey="email" />
        </Column>
        <Column width={100}>
          <HeaderCell>Contact</HeaderCell>
          <Cell dataKey="contact" />
        </Column>
        <Column width={100}>
          <HeaderCell>Date</HeaderCell>
          <Cell>
            {rowdata => (
              <>
                {moment(rowdata.created_at).format("DD-MM-YYYY")}
                <br />
                <span style={{ color: "green" }}>
                  {rowdata?.acc_activated_at?.replace("<br>", " ") && moment(
                    rowdata?.acc_activated_at?.replace("<br>", " ")
                  ).format("DD-MM-YYYY")}
                </span>
              </>
            )}
          </Cell>
        </Column>
        {/* <Column width={100}>
          <HeaderCell>Approved On</HeaderCell>
          <Cell>{rowdata => <></>}</Cell>
        </Column> */}
        <Column width={120} align="center">
          <HeaderCell>Draft/Active</HeaderCell>
          <Cell>
            {rowdata => (
              <>
                Draft: {rowdata.noOfDraft}
                <br />
                Active: {rowdata.noOfActive}
              </>
            )}
          </Cell>
        </Column>
        <Column width={120} align="center">
          <HeaderCell>Overdue</HeaderCell>
          <Cell>
            {rowdata => (
              <>
                Resolved: {rowdata.noOfRes}
                <br />
                Escalated: {rowdata.noOfEsc}
                <br />
                Overdue: {rowdata.noOfOverdue}
              </>
            )}
          </Cell>
        </Column>
        <Column width={80} align="center">
          <HeaderCell>Action</HeaderCell>
          <Cell>
            {rowdata => (
              <>
                <Link to={"/admin/user-details/" + btoa(rowdata.id)}>
                  <IconButton
                    size="xs"
                    appearance="primary"
                    color="orange"
                    icon={<VisibleIcon />}
                  />
                </Link>
                &nbsp;
                <Link to={"/admin/user-letterhead/" + btoa(rowdata.id)}>
                  <IconButton
                    size="xs"
                    appearance="primary"
                    color="violet"
                    icon={<PageIcon />}
                  />
                </Link>
              </>
            )}
          </Cell>
        </Column>
      </Table>
      <Pagination
        className="my-4"
        prev
        next
        first
        last
        ellipsis
        boundaryLinks
        maxButtons={5}
        size="xs"
        layout={["total", "-", "limit", "|", "pager", "skip"]}
        total={props.tableCount}
        limitOptions={[50, 100, 200]}
        limit={limit}
        activePage={page}
        onChangePage={setPage}
        onChangeLimit={handleChangeLimit}
      />
    </div>
  )
}

const mapStateToProps = state => ({
  data: state.Users.items,
  tableLoading: state.Users.loading,
  tableCount: state.Users.count,
  token: state.Login.token,
})

export default connect(mapStateToProps)(ApprovedUsers)
