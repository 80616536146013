const { default: axios } = require("axios");

 export const PdfService = (data1) => {
    let data = new FormData();
    data.append('html',data1.html);
    data.append('letterhead',data1.lh);
    data.append('signature',data1.sign_image);
    data.append('configuration',new Blob([JSON.stringify({
      "headMargin": data1.headermargin,
      "footMargin": data1.footermargin,
      "signatureWidth": data1.sign_width,
      "signatureHeight": data1.sign_height
    })],{
      type: "application/json"
    }));
    
    let config = {
      method: 'post',
      url: 'https://tools.incase360.com/notice-management-dev/template-pdf', 
      data : data
    };
     return axios.request(config)
      
}