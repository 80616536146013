import React, { useEffect, useState } from "react"
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Col,
  Row,
  CustomInput,
  FormFeedback,
} from "reactstrap"
import { FileUploader } from "react-drag-drop-files"
import { CheckPicker, CustomProvider, SelectPicker } from "rsuite"
import Editor from "common/Editor"
import { connect } from "react-redux"
import { ApiCall } from "common/ActionApiCall/ApiCall"
import { useHistory } from "react-router-dom"
import { ShowNotification } from "common/ShowNotification"
import { useLocation } from "react-router-dom"

function OfferForm(props) {
  const location = useLocation()

  const requestData = {
    offer_name: null,
    description: null,
    offer_value: null,
    valid_from: null,
    valid_to: null,
    token: props?.token,
  }

  const [request, setRequest] = useState(requestData)
  const [buttonDisabled, setButtonDisabled] = useState(true)
  const history = useHistory()

  const handleSubmit = e => {
    e.preventDefault()
    // request?.bulk_csv && setShowError({ ...showError, bulk_csv: true })
    request?.offer_name &&
      request?.description &&
      request?.valid_from &&
      request?.valid_to &&
      ApiCall(request, "admin/addOffer", false, result => {
        if (result?.data?.response === "success") {
          ShowNotification(result?.data?.message, "success")
          // history.push("/admin/existingnotice")
        } else {
          ShowNotification(result?.data?.message, "danger")
        }
      })
  }

  useEffect(() => {
    request?.offer_name &&
    request?.description &&
    request?.valid_from &&
    request?.valid_to
      ? setButtonDisabled(false)
      : setButtonDisabled(true)
  }, [request])

  return (
    <div>
      <Form method="post" onSubmit={handleSubmit}>
        <FormGroup>
          <Label for="offer_name">Offer Name :</Label>
          <Input
            type="text"
            name="offer_name"
            id="offer_name"
            placeholder="Enter the name of the Offer"
            onChange={e =>
              setRequest({ ...request, offer_name: e.target.value })
            }
          />
        </FormGroup>
        <FormGroup>
          <Label className="mt-3" for="description">
            Description :
          </Label>
          <Input
            type="textarea"
            name="description"
            id="description"
            placeholder="Enter Description"
            onChange={e =>
              setRequest({ ...request, description: e.target.value })
            }
          />
        </FormGroup>
        <FormGroup>
          <Label className="mt-3" for="offer_value">
            Offer Value :
          </Label>
          <Input
            type="text"
            name="offer_value"
            id="offer_value"
            placeholder="Enter the name of the Offer"
            onChange={e =>
              setRequest({ ...request, offer_value: e.target.value })
            }
          />
        </FormGroup>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label className="mt-3" for="valid_from">
                Valid Date From :
              </Label>
              <Input
                type="date"
                name="valid_from"
                id="valid_from"
                onChange={e =>
                  setRequest({ ...request, valid_from: e.target.value })
                }
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label className="mt-3" for="valid_to">
                Valid Date To :
              </Label>
              <Input
                type="date"
                name="valid_to"
                id="valid_to"
                onChange={e =>
                  setRequest({ ...request, valid_to: e.target.value })
                }
              />
            </FormGroup>
          </Col>
        </Row>
        <Button
          type="submit"
          className="my-4"
          color="primary"
          size="md"
          disabled={buttonDisabled}
        >
          Submit
        </Button>{" "}
      </Form>
    </div>
  )
}

const mapStateToProps = state => ({
  token: state.Login.token,
})

export default connect(mapStateToProps)(OfferForm)
