import React, { useEffect, useState } from 'react'
import { FileUploader } from 'react-drag-drop-files';
import { Modal,Drawer, IconButton, Whisper ,Tooltip} from 'rsuite';
import TrashIcon from '@rsuite/icons/Trash';
import {
    Button,
    Form,
    FormGroup,
    Label,
    Input,
  } from "reactstrap"
import { ShowNotification } from "common/ShowNotification"
import { PdfService } from './PdfService';
import GearIcon from '@rsuite/icons/Gear';

   const PdfView = ({open,setOpen,src}) => {
    const handleClose = () => setOpen(false);
    return (
         <Drawer open={open} onClose={handleClose} style={{width:"60vw"}}>
        <Drawer.Body>
        <iframe style={{width:"100%",height:"100vh"}} src={src}  />
        </Drawer.Body>
      </Drawer>
       
      ); 
   }

   function Preview({open,setOpen,getConfig}) {
   const [configLH,setConfigLH] = useState ({
      headermargin:"90",
      footermargin:"10",
     lh_image:"",
     signature:"",
     sign_width:"100",
     sign_height:"50",
     lh_image_filename:"",
     signature_filename:"",
     lh:"",
     sign_image:""
   })

    const handleSubmit = () =>{
        getConfig({...configLH,isSubmit:true})
      setOpen(false)
    }
    return (
          <Modal open={open} onClose={handleSubmit}>
          <span style={{fontSize:"1em",cursor:"pointer",marginLeft:"93%",backgroundColor:"#fff",padding:".5em .7em",color:"#000",borderRadius:"5px"}} onClick={handleSubmit}>X</span>
            <Modal.Body>
            
            <Form>
              <FormGroup>
                <Label for="headerMargin" className="mt-2">
                  Letterhead Header Margin (in mm) :
                </Label>
                <Input
                  type="number"
                  name="headerMargin"
                  placeholder="Enter the Letterhead Header Margin"
                  defaultValue={configLH?.headermargin}
                  onChange={e =>
                    setConfigLH({ ...configLH, headermargin: e.target.value })
                  }
                />
              </FormGroup>
              <FormGroup>
                <Label for="footerMargin" className="mt-2">
                  Letterhead Footer Margin (in mm) :
                </Label>
                <Input
                  type="number"
                  name="footerMargin"
                  defaultValue={configLH?.footermargin}
                  placeholder="Enter the Letterhead Footer Margin"
                  onChange={e =>
                    setConfigLH({ ...configLH, footermargin: e.target.value })
                  }
                />
              </FormGroup>
              <FormGroup>
                <Label className="mt-2" for="letterhead">
                  Letterhead :
                </Label>
                <FileUploader
                  name="letterhead"
                  types={["jpeg", "jpg", "png"]}
                  handleChange={(e) =>
                    setConfigLH({ ...configLH,lh_image_filename:e.name,lh_image:URL.createObjectURL(e),lh:e})
                  }
                />
                {
configLH.lh_image_filename &&  <div style={{display:"flex",gap:"2em",alignItems:"baseline",padding:".5em 1em"}}>
     <p>{configLH.lh_image_filename} </p>
<TrashIcon onClick={() =>
                    setConfigLH({ ...configLH,lh_image_filename:null,lh_image:null,lh:null})} color='red' style={{fontSize:"1.3em"}}/>
</div>

}     
              </FormGroup>
              <FormGroup>
                <Label className="mt-2" for="signature">
                  Specimen Signature :
                </Label>
                <FileUploader
                  name="signature"
                  types={["jpeg", "jpg", "png"]}
                  handleChange={(e) =>
                    setConfigLH({ ...configLH,signature_filename:e.name, signature: URL.createObjectURL(e),sign_image:e })
                  }
                />
                  
{
configLH.signature_filename &&  <div style={{display:"flex",gap:"2em",alignItems:"baseline",padding:".5em 1em"}}>
     <p>{configLH.signature_filename} </p>
     <TrashIcon onClick={() =>
                    setConfigLH({ ...configLH,signature_filename:null,signature:null,sign_image:null})} color='red' style={{fontSize:"1.3em"}}/>
</div>

}             
              </FormGroup>
              <FormGroup>
                <Label for="footerMargin" className="mt-2">
                  Letterhead Signature Width (in px) :
                </Label>
                <Input
                  type="number"
                  name="signatureWidth"
                  defaultValue={configLH?.sign_width}
                  placeholder="Enter the Letterhead Signature Width"
                  onChange={e =>
                    setConfigLH({ ...configLH, sign_width: e.target.value })
                  }
                />
              </FormGroup>
              <FormGroup>
                <Label for="footerMargin" className="mt-2">
                  Letterhead Signature Height (in px) :
                </Label>
                <Input
                  type="number"
                  name="signatureHeight"
                   defaultValue={configLH?.sign_height}
                  placeholder="Enter the Letterhead Signature Height"
                  onChange={e =>
                    setConfigLH({ ...configLH, sign_height: e.target.value })
                  }
                />
              </FormGroup>
            </Form>
       
            </Modal.Body>
          </Modal>
       
      );
}



export default function PdfViewer({html}) {
    const [open,setOpen]= useState(false)
    const [config,setconfig] = useState({})
    const [preview,setpreview] = useState(false)
    const [src,setsrc] = useState("")
    const getAllConfig = (data) =>{
        setconfig(data)
        console.log(data)
      }
      function closeModal (value) {
        setOpen(value)
        setpreview(value)
      }
  return (
    <div style={{display:"flex",gap:"1em",alignItems:"center"}}>
       <PdfView open={preview} setOpen={closeModal} src={src}/>
       <Preview open={open} setOpen={closeModal} getConfig={getAllConfig}/>
   <Button
    type="button"
    className="my-4"
    color="warning"
    size="md"
    onClick={() =>{
      PdfService({...config,html:html})
      .then((response) => {
         if(response.status === 200) {
          setpreview(true)
          const byteCharacters = atob(response.data.data);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: 'application/pdf' });
          const url = URL.createObjectURL(blob);
             setsrc(url)
         }
         else {
          ShowNotification(e?.response?.data?.errors[0], "danger")
         }
  
      })
      .catch((e)=>{
        ShowNotification(e?.response?.data?.errors[0], "danger")
      })
    }
    }
  >
  Preview
  </Button>
  <Whisper followCursor speaker={<Tooltip> Set Notice Configuration</Tooltip>}>
       <GearIcon  onClick={() => setOpen(true)}  style={{ fontSize: '1.8em',color:"#ffff" }} />
  </Whisper>
  </div>
  )
}
