import { ApiCall } from "common/ActionApiCall/ApiCall"
import Editor from "common/Editor"
import { ShowNotification } from "common/ShowNotification"
import React, { useEffect, useState } from "react"
import { FileUploader } from "react-drag-drop-files"
import { connect } from "react-redux"
import { useHistory, useLocation } from "react-router-dom"
import { Button, Form, FormFeedback, FormGroup, Input, Label } from "reactstrap"
import { CheckPicker, CustomProvider } from "rsuite"
import { Toggle } from 'rsuite';
import PdfViewer from "../PDFViewer/PdfViewer"
function NoticeForm(props) {
  const location = useLocation()
  const [Express,setExpress] = useState(false)
  const requestData = {
    noticeType: null,
    noticeDescription: null,
    draft_notice_html: location?.state?.draft_notice_html || null,
    willLinkExpire: 0,
    send_as: 2,
    autoGeneratePdf: 1,
    token: props?.token,
    bulk_csv: null,
    userId: null,
    canvaSubtitle: null,
    enterpriseAbbr: null,
    emailEnabled: 1,
    smsEnabled: 1,
    whatsappEnabled: 1,
    rpadEnabled: 1,
  pdfGenerationStrategy:"STANDARD"
  }
  const [request, setRequest] = useState(requestData)
  const [buttonDisabled, setButtonDisabled] = useState(true)
  const history = useHistory()

  const userdata = props?.userdata?.map(item => ({
    label: item?.organization_name,
    value: item?.id,
  }))

  const handleFileChange = file => {
    setRequest({ ...request, bulk_csv: file })
  }

  const handleLinkExpireChange = value => {
    setRequest({ ...request, willLinkExpire: value })
  }

  const handleSendAsChange = value => {
    setRequest({ ...request, send_as: value })
  }

  const handleAutoGeneratePdfChange = value => {
    setRequest({ ...request, autoGeneratePdf: value })
  }
  const handleCommunicationModeChange = (mode, isChecked) => {
    let updatedRequest = { ...request }
    switch (mode) {
      case 1: // Email
        updatedRequest.emailEnabled = isChecked ? 1 : 0
        break
      case 2: // SMS
        updatedRequest.smsEnabled = isChecked ? 1 : 0
        break
      case 3: // WhatsApp
        updatedRequest.whatsappEnabled = isChecked ? 1 : 0
        break
      case 4: // RPAD
        updatedRequest.rpadEnabled = isChecked ? 1 : 0
        break
      default:
        break
    }

    setRequest(updatedRequest)
  }

  const handleSubmit = e => {
    e.preventDefault()
    // request?.bulk_csv && setShowError({ ...showError, bulk_csv: true })
    request?.bulk_csv &&
      request?.noticeType &&
      request?.noticeDescription &&
      request?.draft_notice_html &&
      ApiCall(request, "admin/save-notice", false, result => {
        if (result?.data?.response === "success") {
          ShowNotification(result?.data?.message, "success")
          history.push("/admin/existingnotice")
        } else {
          ShowNotification(result?.data?.message, "danger")
        }
      })
  }

  const handlePreview = () => {
    const passData = {
      token: props?.token,
      noticeContent: request?.draft_notice_html,
    }
    ApiCall(passData, "previewNoticeContent", false, result => {
      if (result?.data?.response === "success") {
        ShowNotification(result?.data?.message, "success")
        const link = document.createElement("a")
        // link.target = "_blank"
        link.target = "_blank"
        link.href = result?.data?.url
        document.body.appendChild(link)
        link.click()
        link.parentNode.removeChild(link)
      } else {
        ShowNotification(result?.data?.message, "danger")
      }
    })
  }

  useEffect(() => {
    request?.bulk_csv &&
    request?.noticeType &&
    request?.noticeDescription &&
    request?.draft_notice_html
      ? setButtonDisabled(false)
      : setButtonDisabled(true)
  }, [request])

  return (
    <div>
    <Toggle style={{marginLeft:"85%"}} checkedChildren="Express" onChange={(isChecked)=>{
        setExpress(isChecked)
        isChecked ?  setRequest({ ...request,   pdfGenerationStrategy:"EXPRESS" }) :  setRequest({ ...request,  pdfGenerationStrategy:"STANDARD" })     
    }}  unCheckedChildren="Standard"/>
      <Form method="post" onSubmit={handleSubmit}>
        <FormGroup>
          <Label for="noticeType">Notice Type :</Label>
          <Input
            type="text"
            name="noticeType"
            id="noticeType"
            placeholder="Enter the name of the notice"
            onChange={e =>
              setRequest({ ...request, noticeType: e.target.value })
            }
          />
          <FormFeedback>Notice Type Filed is Required</FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label className="mt-3" for="noticeDescription">
            Notice Description(max: 100 words) :
          </Label>
          <Input
            type="textarea"
            name="noticeDescription"
            id="noticeDescription"
            placeholder="Enter Notice Description"
            onChange={e =>
              setRequest({ ...request, noticeDescription: e.target.value })
            }
          />
          <FormFeedback>Notice Description Filed is Required</FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label className="mt-3" for="noticeDescription">
            Assign User :
          </Label>
          <br />
          <CustomProvider theme="dark">
            {/* <SelectPicker
              size="sm"
              label="User"
              data={userdata}
              className="w-100"
            /> */}
            <CheckPicker
              size="sm"
              data={userdata}
              placeholder="Select User"
              onChange={e => setRequest({ ...request, userId: e })}
              block
            />
          </CustomProvider>
        </FormGroup>
        <FormGroup>
          <Label className="mt-3" for="draftNotice">
            Create Draft Notice :
          </Label>
          <Editor request={request} setRequest={setRequest} />

          {
             Express ? <PdfViewer html={request.draft_notice_html} /> : <Button
             type="button"
             className="my-4"
             color="warning"
             size="md"
             onClick={() => handlePreview()}
           >
             Preview
           </Button>
          }
       
        </FormGroup>
        <FormGroup>
          <Label className="mt-3" for="bulkUpload">
            Upload Format for Bulk Upload :
          </Label>
          <FileUploader
            name="file"
            types={["csv"]}
            handleChange={handleFileChange}
            onTypeError={e =>
              e &&
              setRequest({
                ...request,
                bulk_csv: null,
              })
            }
          />
          <p style={{ color: "green" }}>
            {request?.bulk_csv && `${request?.bulk_csv?.name}`}
          </p>
        </FormGroup>
        <FormGroup className="mt-3">
          <Label for="linkExpire">Will Reply Link Expire ? :</Label>
          <samp className="px-3">
            <Input
              style={{ marginRight: "0.5em" }}
              name="linkExpire"
              type="radio"
              checked={request.willLinkExpire === 1}
              onChange={() => handleLinkExpireChange(1)}
            />
            <Label check>Yes</Label>
          </samp>
          <span>
            <Input
              style={{ marginRight: "0.5em" }}
              name="linkExpire"
              type="radio"
              checked={request.willLinkExpire === 0}
              onChange={() => handleLinkExpireChange(0)}
            />
            <Label check>No</Label>
          </span>
          {/* <Input type="select" name="linkExpire" id="linkExpire">
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </Input> */}
        </FormGroup>
        <FormGroup className="mt-3">
          <Label for="sendAs">Send As :</Label>
          <samp className="px-3">
            <Input
              style={{ marginRight: "0.5em", cursor: "pointer" }}
              name="sendAs"
              type="radio"
              checked={request.send_as === 1}
              onChange={() => handleSendAsChange(1)}
            />
            <Label check>Legal Notice</Label>
          </samp>
          <span>
            <Input
              style={{ marginRight: "0.5em", cursor: "pointer" }}
              name="sendAs"
              type="radio"
              checked={request.send_as === 2}
              onChange={() => handleSendAsChange(2)}
            />
            <Label check>Communication</Label>
          </span>
          {/* <Input type="select" name="linkExpire" id="linkExpire">
            <option value="">-- Select --</option>
            <option value="yes">Legel Notice</option>
            <option value="no">Communication</option>
          </Input> */}
        </FormGroup>{" "}
        <FormGroup className="mt-3" inline>
          <Label for="communicationModes">Communication Modes:</Label>
          <div style={{ display: "flex" }}>
            <div style={{ marginRight: "20px" }}>
              <Input
                type="checkbox"
                id="email"
                checked={request.emailEnabled === 1}
                onChange={e =>
                  handleCommunicationModeChange(1, e.target.checked)
                }
                style={{ marginRight: "5px" }}
              />
              <Label for="email">Email</Label>
            </div>
            <div style={{ marginRight: "20px" }}>
              <Input
                type="checkbox"
                id="sms"
                checked={request.smsEnabled === 1}
                onChange={e =>
                  handleCommunicationModeChange(2, e.target.checked)
                }
                style={{ marginRight: "5px" }}
              />
              <Label for="sms">SMS</Label>
            </div>
            <div style={{ marginRight: "20px" }}>
              <Input
                type="checkbox"
                id="whatsapp"
                checked={request.whatsappEnabled === 1}
                onChange={e =>
                  handleCommunicationModeChange(3, e.target.checked)
                }
                style={{ marginRight: "5px" }}
              />
              <Label for="whatsapp">WhatsApp</Label>
            </div>
            <div>
              <Input
                type="checkbox"
                id="rpad"
                checked={request.rpadEnabled === 1}
                onChange={e =>
                  handleCommunicationModeChange(4, e.target.checked)
                }
                style={{ marginRight: "5px" }}
              />
              <Label for="rpad">RPAD</Label>
            </div>
          </div>
        </FormGroup>
        <FormGroup className="mt-3">
          <Label for="autoGeneratePdf">System generated pdf required? :</Label>
          <samp className="px-3">
            <Input
              style={{ marginRight: "0.5em", cursor: "pointer" }}
              name="autoGeneratePdf"
              type="radio"
              checked={request.autoGeneratePdf === 1}
              onChange={() => handleAutoGeneratePdfChange(1)}
            />
            <Label check>Yes</Label>
          </samp>
          <span>
            <Input
              style={{ marginRight: "0.5em", cursor: "pointer" }}
              name="autoGeneratePdf"
              type="radio"
              checked={request.autoGeneratePdf === 0}
              onChange={() => handleAutoGeneratePdfChange(0)}
            />
            <Label check>No</Label>
          </span>
          {/* <Input type="select" name="linkExpire" id="linkExpire">
            <option value="">-- Select --</option>
            <option value="yes">Legel Notice</option>
            <option value="no">Communication</option>
          </Input> */}
        </FormGroup>
        <FormGroup>
          <Label for="canvaSubtitle">Subtitle for Canva :</Label>
          <Input
            type="text"
            name="canvaSubtitle"
            id="canvaSubtitle"
            placeholder="Enter the Subtitle for Canva"
            onChange={e =>
              setRequest({ ...request, canvaSubtitle: e.target.value })
            }
          />
          <FormFeedback>Canva Subtitle is Required</FormFeedback>
        </FormGroup>
        
        <FormGroup>
          <Label for="enterpriseAbbr">Enterprise Abbr :</Label>
          <Input
            type="text"
            name="enterpriseAbbr"
            id="enterpriseAbbr"
            placeholder="Enter Enterprise Abbr"
            onChange={e =>
              setRequest({ ...request, enterpriseAbbr: e.target.value })
            }
          />
          <FormFeedback>Enterprise Abbr is Required</FormFeedback>
        </FormGroup>

        <Button
          type="submit"
          className="my-4"
          color="primary"
          size="md"
          disabled={buttonDisabled}
        >
          Submit
        </Button>{" "}
      </Form>
    </div>
  )
}

const mapStateToProps = state => ({
  token: state.Login.token,
})

export default connect(mapStateToProps)(NoticeForm)
