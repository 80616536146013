import PropTypes from "prop-types"
import React, { useEffect, useRef } from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"
import { getCountData } from "store/actions"
import { connect, useDispatch } from "react-redux"
// import { Badge } from "reactstrap"
import { Badge } from "rsuite"
import SettingHorizontalIcon from "@rsuite/icons/SettingHorizontal"
import ManuallyBadge from "components/Common/ManuallyBadge"
import SentToUserIcon from '@rsuite/icons/SentToUser';
import DocPassIcon from '@rsuite/icons/DocPass';

const SidebarContent = props => {
  const dispatch = useDispatch()
  const ref = useRef()
  useEffect(() => {
    const requestPayload = {
      token: props.token,
    }
    dispatch(getCountData(requestPayload))
  }, [])
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }

  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">Main </li>
            <li>
              <Link to="/admin/dashboard" className="waves-effect">
                <i className="mdi mdi-view-dashboard"></i>
                {/* <span className="badge rounded-pill bg-primary float-end">
									2
								</span> */}
                <span>Dashboard</span>
              </Link>
            </li>

            <li>
              <Link to="/admin/noticelist" className="waves-effect">
                <i className="mdi mdi-clipboard-list"></i>
                <span>All Notices</span>
              </Link>
            </li>

            <li>
              <Link to="/admin/addnotice" className="waves-effect">
                <i className="mdi mdi-clipboard-plus"></i>
                <span>Add New Notice</span>
              </Link>
            </li>

            <li>
              <Link to="/admin/existingnotice" className="waves-effect">
                <i className="mdi mdi-view-list"></i>
                <span>Existing Notice</span>
              </Link>
            </li>
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i>
                  <SentToUserIcon/>
                </i>
                <span>User Action</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/admin/user-action/upload-csv">
                    CSV Upload{" "}

                  </Link>
                </li>
                <li>
                  <Link to="/admin/user-action/bulk-send">
                    Bulk Send{" "}
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-account-group"></i>
                <span>Manage Users</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/admin/users/newrequest">
                    New Request{" "}
                    <ManuallyBadge
                      color="#673ab7"
                      show={props?.data?.userNewCount}
                    />
                  </Link>
                </li>
                <li>
                  <Link to="/admin/users/approved">
                    Approved{" "}
                    <ManuallyBadge
                      color="green"
                      show={props?.data?.userApproveCount}
                    />
                  </Link>
                </li>
                <li>
                  <Link to="/admin/users/unapproved">
                    Unapproved{" "}
                    <ManuallyBadge
                      color="red"
                      show={props?.data?.userUnapproveCount}
                    />
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/admin/report" className="waves-effect">
                <i className="mdi mdi-file-document-multiple"></i>
                <span>CSV Report</span>
              </Link>
            </li>
            <li>
              <Link to="/admin/letterheadApproval" className="waves-effect">
                <i className="mdi mdi-file-document"></i>
                <span>
                  Letterhead Approval{" "}
                  <ManuallyBadge
                    color="#28c9ab"
                    show={props?.data?.all_Letterhead_count}
                  />{" "}
                </span>
              </Link>
            </li>
            <li>
              <Link to="/admin/filesharing" className="waves-effect">
                <i className="mdi mdi-share-all"></i>
                <span>File Sharing</span>
              </Link>
            </li>
            <li>
              <Link to="/admin/auto-report" className="waves-effect">
                <i>
                  <DocPassIcon />
                </i>
                <span>Auto Report</span>
              </Link>
            </li>
            <li>
              <Link to="/admin/auto-download" className="waves-effect">
                <i>
                  <DocPassIcon />
                </i>
                <span>Auto Download</span>
              </Link>
            </li>
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-cards"></i>
                <span>Manage Offers</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/admin/offer/add-new-offer">Add New Offers</Link>
                </li>
                <li>
                  <Link to="/admin/offer/offers-list">Offers List</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/admin/settings" className="waves-effect">
                {/* <i className="mdi mdi-mdi-settings"></i> */}
                <i>
                  <SettingHorizontalIcon />
                </i>
                <span>Settings</span>
              </Link>
            </li>
            {/* <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="mdi mdi-email-outline"></i>
                  <span>Email</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/email-inbox">Inbox</Link>
                  </li>
                  <li>
                    <Link to="/email-read">Email Read </Link>
                  </li>
                  <li>
                    <Link to="/email-compose">Email Compose </Link>
                  </li>
                </ul>
              </li> */}

            {/* <li className="menu-title">Components</li> */}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

const mapStateToProps = state => ({
  data: state.CountData.items,
  token: state.Login.token,
})

export default withRouter(connect(mapStateToProps)(SidebarContent))
