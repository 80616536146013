import React, { useEffect, useState } from "react"
import { CustomProvider, IconButton, Pagination, Table, Input } from "rsuite"
import FileDownloadIcon from "@rsuite/icons/FileDownload"
import EyeIcon from "@rsuite/icons/legacy/Eye"
import { connect, useDispatch } from "react-redux"
import WhisperTooltip from "components/Common/Whisper/WhisperTooltip"
import { getAutoReport } from "store/actions"
import { Link, useHistory } from "react-router-dom"
import useWindowDimensions from "common/useWindowDimensions"
import { RouteForShow, RouteWithoutApi } from "common/ApiRoute"
import { DownloadApiCall } from "common/ActionApiCall/DownloadApiCall"

const { Column, HeaderCell, Cell } = Table

const AutoReportTable = props => {
  const [limit, setLimit] = useState(50)
  const [page, setPage] = useState(1)
  const dispatch = useDispatch()
  const { height, width } = useWindowDimensions()

  const handleChangeLimit = dataKey => {
    setPage(1)
    setLimit(dataKey)
  }

  const downloadClickFile = file => {
    const request = {
      token: props?.token,
      folder: "auto_report",
      filename: file,
    }
    DownloadApiCall(request, "download/single-download", "_blank")
  }

  useEffect(() => {
    const displayStart = limit * (page - 1)

    const requestPayload = {
      token: props.token,
      page: page,
      iDisplayStart: displayStart,
      iDisplayLength: limit,
      //   searchValue: search?.value,
    }
    dispatch(getAutoReport(requestPayload))
  }, [page, limit, props?.apiAction?.action])

  return (
    <div className="mt-4">
      <h4>View Auto Report</h4>
      <div className="table-responsive mt-4">
        <Table
          height={height - 350}
          data={props?.data}
          wordWrap="break-word"
          headerHeight={46}
          loading={props?.tableLoading}
        >
          <Column width={80} align="center">
            <HeaderCell>Sr.No.</HeaderCell>
            <Cell>
              {(rowdata, key) => (
                <>
                  <span>
                    {page === 1 ? key + 1 : limit * (page - 1) + key + 1}
                  </span>
                </>
              )}
            </Cell>
          </Column>
          <Column flexGrow={2}>
            <HeaderCell>Notice</HeaderCell>
            <Cell dataKey="notice_type" />
          </Column>
          <Column flexGrow={2}>
            <HeaderCell>Batch</HeaderCell>
            <Cell dataKey="batch_name" />
          </Column>
          <Column flexGrow={1}>
            <HeaderCell>Report On</HeaderCell>
            <Cell dataKey="report_on" />
          </Column>
          <Column flexGrow={1} align="center">
            <HeaderCell>Normal</HeaderCell>
            <Cell>
              {rowdata => (
                <>
                  <>
                    <WhisperTooltip
                      placement="top"
                      trigger="hover"
                      tooltipMsg="Download File"
                    >
                      <IconButton
                        size="xs"
                        appearance="primary"
                        color="green"
                        icon={<FileDownloadIcon />}
                        disabled={rowdata?.normal_report === null}
                        onClick={() =>
                          downloadClickFile(rowdata?.normal_report)
                        }
                      />
                    </WhisperTooltip>
                  </>
                </>
              )}
            </Cell>
          </Column>
          <Column flexGrow={1} align="center">
            <HeaderCell>Preliminary</HeaderCell>
            <Cell>
              {rowdata => (
                <>
                  <>
                    <WhisperTooltip
                      placement="top"
                      trigger="hover"
                      tooltipMsg="Download File"
                    >
                      <IconButton
                        size="xs"
                        appearance="primary"
                        color="green"
                        icon={<FileDownloadIcon />}
                        disabled={rowdata?.preliminary_report === null}
                        onClick={() =>
                          downloadClickFile(rowdata?.preliminary_report)
                        }
                      />
                    </WhisperTooltip>
                  </>
                </>
              )}
            </Cell>
          </Column>
          <Column flexGrow={1} align="center">
            <HeaderCell>Interim</HeaderCell>
            <Cell>
              {rowdata => (
                <>
                  <>
                    <WhisperTooltip
                      placement="top"
                      trigger="hover"
                      tooltipMsg="Download File"
                    >
                      <IconButton
                        size="xs"
                        appearance="primary"
                        color="green"
                        icon={<FileDownloadIcon />}
                        disabled={rowdata?.interim_report === null}
                        onClick={() =>
                          downloadClickFile(rowdata?.interim_report)
                        }
                      />
                    </WhisperTooltip>
                  </>
                </>
              )}
            </Cell>
          </Column>
          <Column flexGrow={1} align="center">
            <HeaderCell>Final</HeaderCell>
            <Cell>
              {rowdata => (
                <>
                  {rowdata?.final_report === null ? (
                    "--"
                  ) : (
                    <WhisperTooltip
                      placement="top"
                      trigger="hover"
                      tooltipMsg="Download File"
                    >
                      <IconButton
                        size="xs"
                        appearance="primary"
                        color="green"
                        icon={<FileDownloadIcon />}
                        onClick={() => downloadClickFile(rowdata?.final_report)}
                        // disabled={rowdata?.final_report === null}
                      />
                    </WhisperTooltip>
                  )}
                </>
              )}
            </Cell>
          </Column>
          <Column flexGrow={1} align="center">
            <HeaderCell>Canva</HeaderCell>
            <Cell>
              {rowdata => (
                <>
                  {rowdata?.final_report === null ? (
                    "--"
                  ) : (
                    <WhisperTooltip
                      placement="top"
                      trigger="hover"
                      tooltipMsg="View Canva Report"
                    >
                      <Link
                        // to={{
                        //   pathname: "/admin/canva-report/" + rowdata.id,
                        //   state: { prevPath: location.pathname },
                        // }}
                        to={{
                          pathname:
                            RouteWithoutApi + "autoCanvaReport/" + rowdata?.id,
                        }}
                        target="_blank"
                      >
                        <IconButton
                          size="xs"
                          appearance="primary"
                          color="blue"
                          icon={<EyeIcon />}
                          // disabled={rowdata?.final_report === null}
                        />
                      </Link>
                    </WhisperTooltip>
                  )}
                </>
              )}
            </Cell>
          </Column>
        </Table>
        <Pagination
          className="my-4"
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          size="xs"
          layout={["total", "-", "limit", "|", "pager", "skip"]}
          total={props.tableCount}
          limitOptions={[50, 100, 200]}
          limit={limit}
          activePage={page}
          onChangePage={setPage}
          onChangeLimit={handleChangeLimit}
        />
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  data: state.AutoReport.items,
  tableLoading: state.AutoReport.loading,
  tableCount: state.AutoReport.count,
  token: state.Login.token,
})

export default connect(mapStateToProps)(AutoReportTable)
