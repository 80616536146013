import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { connect } from "react-redux"
import { Checkbox, CustomProvider } from "rsuite"
import { setBreadcrumbItems } from "../../../store/actions"
import ResolvedTable from "./ResolvedTable"
import BulkMenu from "../BulkMenu"
import { DownloadApiCall } from "common/ActionApiCall/DownloadApiCall"
import { useParams } from "react-router"
import FormGroup from "components/Common/FormGroup"
import FilterMenu from "../FilterMenu"
import NewResolvedTable from "./NewResolvedTable"

const UserResolved = props => {
  const [checkedKeys, setCheckedKeys] = useState([])
  const [openSubMenu, setOpenSubMenu] = useState(false)
  const [stateCsv, setStateCsv] = useState()
  const [openFilterMenu, setOpenFilterMenu] = useState(false)
  const [fromTo, setFromTo] = useState({
    rangeFrom: "",
    rangeTo: "",
    rangeExcept: "",
  })
  const [downloadAs, setDownloadAs] = useState("notice_id")
  const [deliveryTracking, setDeliveryTracking] = useState("0")
  const params = useParams()
  const [filter, setFilter] = useState({
    batch: "",
    notesAdded: "",
    replyAdded: "",
    undeliveredWhatsapp: 0,
    undeliveredEmail: 0,
    undeliveredSms: 0,
    case_ref_id: "",
    submit: false,
    filterFrom: "",
    filterTo: "",
  })
  const breadcrumbItems = params?.userId
    ? [
        { title: "Incase360", link: "#" },
        { title: "Sub User", link: "/user/subuser" },
        {
          title: "Sub User Notice List",
          link: "/user/subuser/noticelist/" + params?.userId,
        },
        { title: "Sub User Rejected Notice Details", link: "#" },
      ]
    : [
        { title: "Incase360", link: "#" },
        { title: "Notice List", link: "/user/noticelist" },
        { title: "Resolved Notice Details", link: "#" },
      ]

  const handleBulkDownloadNotices = () => {
    // if()
    const request = {
      token: props?.token,
      all_ids: checkedKeys,
      mnid: params?.id,
      download_as: downloadAs,
      deliveryTracking: deliveryTracking,
      refrenceCsv: stateCsv,
      ...fromTo,
    }
    DownloadApiCall(request, "download/download-notice", "_self")
  }

  const handleBulkDownloadReport = () => {
    // if()
    const request = {
      token: props?.token,
      all_ids: checkedKeys,
      mnid: params?.id,
      refrenceCsv: stateCsv,
      ...fromTo,
    }
    DownloadApiCall(request, "download/download-track", "_self")
  }

  const handleBulkDownloadCourierReceipts = () => {
    // if()
    const request = {
      token: props?.token,
      all_ids: checkedKeys,
      mnid: params?.id,
      refrenceCsv: stateCsv,
      ...fromTo,
    }
    DownloadApiCall(request, "download/courier-receipts", "_self")
  }

  const handleBulkDownloadReportTesting = () => {
    // if()
    const request = {
      token: props?.token,
      all_ids: checkedKeys,
      mnid: params?.id,
      ...fromTo,
    }
    DownloadApiCall(request, "download/download-track-testing", "_self")
  }

  const handleBulkDownloadTrackingReports = () => {
    // if()
    const request = {
      token: props?.token,
      all_ids: checkedKeys,
      mnid: params?.id,
      refrenceCsv: stateCsv,
      ...fromTo,
    }
    DownloadApiCall(request, "download/delivery-tracking-report", "_self")
  }

  const bulkButton = [
    {
      id: 1,
      size: "xs",
      color: "orange",
      label: "Bulk Download Notices",
      display:
        checkedKeys.length > 0 ||
        (fromTo?.rangeFrom !== "" && fromTo?.rangeTo !== "") ||
        stateCsv
          ? "d-block"
          : "d-none",
      onClick: handleBulkDownloadNotices,
    },
    {
      id: 2,
      size: "xs",
      color: "violet",
      label: "Bulk Download Report",
      display:
        checkedKeys.length > 0 ||
        (fromTo?.rangeFrom !== "" && fromTo?.rangeTo !== "") ||
        stateCsv
          ? "d-block"
          : "d-none",
      onClick: handleBulkDownloadReportTesting,
    },
    {
      id: 3,
      size: "xs",
      color: "cyan",
      label: "Bulk Delivery Tracking Reports",
      display:
        checkedKeys.length > 0 ||
        (fromTo?.rangeFrom !== "" && fromTo?.rangeTo !== "") ||
        stateCsv
          ? "d-block"
          : "d-none",
      onClick: handleBulkDownloadTrackingReports,
    },
    {
      id: 4,
      size: "xs",
      color: "green",
      label: "Bulk Download Courier Receipts",
      display:
        checkedKeys.length > 0 ||
        (fromTo?.rangeFrom !== "" && fromTo?.rangeTo !== "") ||
        stateCsv
          ? "d-block"
          : "d-none",
      onClick: handleBulkDownloadCourierReceipts,
    },
  ]

  const fromToInput = [
    {
      id: 1,
      name: "rangeFrom",
      placeholder: "From ex. IN12-1234",
      onChange: e => setFromTo({ ...fromTo, rangeFrom: e }),
    },
    {
      id: 2,
      name: "rangeTo",
      placeholder: "To ex. IN12-2345",
      onChange: e => setFromTo({ ...fromTo, rangeTo: e }),
    },
    {
      id: 3,
      name: "rangeExcept",
      placeholder: "Except ex. IN12-23,IN12-34,IN12-45",
      onChange: e => setFromTo({ ...fromTo, rangeExcept: e }),
    },
  ]

  const fromToDate = [
    {
      id: 1,
      name: "rangeDateFrom",
      placeholder: "From",
      onChange: e => setFilter({ ...filter, filterFrom: e }),
    },
    {
      id: 2,
      name: "rangeDateTo",
      placeholder: "To",
      min: filter?.filterFrom,
      onChange: e => setFilter({ ...filter, filterTo: e }),
    },
  ]

  useEffect(() => {
    props.setBreadcrumbItems(
      `${
        params?.userId ? "Sub User" : ""
      } Resolved Notice Details - ${props?.data?.noticeName?.replace(
        "notice_",
        ""
      )}`,
      breadcrumbItems
    )
  })

  return (
    <React.Fragment>
      <MetaTags>
        <title>Notice Details - Admin | Incase360</title>
      </MetaTags>
      <CustomProvider theme="dark">
        {!params?.userId && (
          <FilterMenu
            openFilterMenu={openFilterMenu}
            setOpenFilterMenu={setOpenFilterMenu}
            state={filter}
            setState={setFilter}
            mnid={params?.id}
            tab="resolve"
            fromToDate={fromToDate}
          />
        )}
        <NewResolvedTable
          checkedKeys={checkedKeys}
          setCheckedKeys={setCheckedKeys}
          setOpenSubMenu={setOpenSubMenu}
          filter={filter}
        />
        {!params?.userId && (
          <BulkMenu
            openSubMenu={openSubMenu}
            setOpenSubMenu={setOpenSubMenu}
            bulkButton={bulkButton}
            state={downloadAs}
            setState={setDownloadAs}
            fromToInput={fromToInput}
            stateDelivery={deliveryTracking}
            setStateDelivery={setDeliveryTracking}
            setStateCsv={setStateCsv}
          />
        )}
      </CustomProvider>
    </React.Fragment>
  )
}

const mapStateToProps = state => ({
  data: state.DraftNotice.items,
  token: state.Login.token,
})

export default connect(mapStateToProps, { setBreadcrumbItems })(UserResolved)
