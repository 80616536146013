import FileDownloadIcon from "@rsuite/icons/FileDownload"
import { ApiCall } from "common/ActionApiCall/ApiCall"
import { RouteForShow } from "common/ApiRoute"
import Editor from "common/Editor"
import { ShowNotification } from "common/ShowNotification"
import WhisperTooltip from "components/Common/Whisper/WhisperTooltip"
import React, { useEffect, useState } from "react"
import { FileUploader } from "react-drag-drop-files"
import { connect } from "react-redux"
import { useHistory } from "react-router-dom"
import { Button, Form, FormGroup, Input, Label } from "reactstrap"
import { IconButton, Toggle } from "rsuite"
import PdfViewer from "../PDFViewer/PdfViewer"
import GearIcon from '@rsuite/icons/Gear';
function NoticeForm(props) {
  const handleLinkExpireChange = value => {
    props?.setRequest({ ...props?.request, willLinkExpire: value })
  }
  const handleSendAsChange = value => {
    props?.setRequest({ ...props?.request, send_as: value })
  }
  const handleAutoGeneratePdfChange = value => {
    props?.setRequest({ ...props?.request, autoGeneratePdf: value })
  }
  const [Express,setExpress] = useState(false)

  // handleCommunicationModeChange  = value => {}
  const handleCommunicationModeChange = (mode, isChecked) => {
    let updatedRequest = { ...props?.request }
    switch (mode) {
      case 1: // Email
        updatedRequest.emailEnabled = isChecked ? 1 : 0
        break
      case 2: // SMS
        updatedRequest.smsEnabled = isChecked ? 1 : 0
        break
      case 3: // WhatsApp
        updatedRequest.whatsappEnabled = isChecked ? 1 : 0
        break
      case 4: // RPAD
        updatedRequest.rpadEnabled = isChecked ? 1 : 0
        break
      default:
        break
    }

    props?.setRequest(updatedRequest)
  }
  const history = useHistory()

  const handleSubmit = e => {
    e.preventDefault()
    // request?.bulk_csv && setShowError({ ...showError, bulk_csv: true })
    props?.request?.token &&
      props?.request?.notice_type &&
      props?.request?.notice_desc &&
      props?.request?.draft_notice_html &&
      ApiCall(props?.request, "admin/edit-notice", false, result => {
        if (result?.data?.response === "success") {
          ShowNotification(result?.data?.message, "success")
          history.push("/admin/existingnotice")
        } else {
          ShowNotification(result?.data?.message, "danger")
        }
      })
  }

  const handlePreview = () => {
    const passData = {
      token: props?.request?.token,
      noticeContent: props?.request?.draft_notice_html,
    }
    ApiCall(passData, "previewNoticeContent", false, result => {
      if (result?.data?.response === "success") {
        ShowNotification(result?.data?.message, "success")
        const link = document.createElement("a")
        // link.target = "_blank"
        link.target = "_blank"
        link.href = result?.data?.url
        document.body.appendChild(link)
        link.click()
        link.parentNode.removeChild(link)
      } else {
        ShowNotification(result?.data?.message, "danger")
      }
    })
  }

  return (
    !props?.loading && (
      <div>
         <Toggle style={{marginLeft:"85%"}} checkedChildren="Express" onChange={(isChecked)=>{
        setExpress(isChecked)
        isChecked ?  props?.setRequest({
          ...props?.request,
          pdfGenerationStrategy:"EXPRESS",
        })  :   props?.setRequest({
          ...props?.request,
          pdfGenerationStrategy:"STANDARD",
        })     
    }}  unCheckedChildren="Standard"/>
        <Form method="post" onSubmit={handleSubmit}>
          <FormGroup>
            <Label for="noticeType">Notice Type :</Label>
            <Input
              type="text"
              name="noticeType"
              id="noticeType"
              placeholder="Enter the name of the notice"
              value={props?.request?.notice_type || ""}
              disabled={props?.existData}
              onChange={e =>
                props?.setRequest({
                  ...props?.request,
                  notice_type: e.target.value,
                })
              }
            />
          </FormGroup>
          <FormGroup>
            <Label className="mt-3" for="noticeDescription">
              Notice Description(max: 100 words) :
            </Label>
            <Input
              type="textarea"
              name="noticeDescription"
              id="noticeDescription"
              placeholder="Enter Notice Description"
              defaultValue={props?.request?.notice_desc || ""}
              onChange={e =>
                props?.setRequest({
                  ...props?.request,
                  notice_desc: e.target.value,
                })
              }
            />
          </FormGroup>
          {/* <FormGroup>
          <Label className="mt-3" for="noticeDescription">
            Assign User :
          </Label>
          <br />
          <CustomProvider theme="dark">
            <SelectPicker size="sm" label="User" data={userdata} className="w-100" />
          </CustomProvider>
        </FormGroup> */}
          <FormGroup>
            <Label className="mt-3" for="draftNotice">
              Draft Notice :
            </Label>
            <Editor
              request={props?.request}
              setRequest={props?.setRequest}
              defaultValue={props?.request?.draft_notice_html || ""}
              editType={true}
            />
           {
             Express ? <PdfViewer html={props?.request?.draft_notice_html} /> : <Button
             type="button"
             className="my-4"
             color="warning"
             size="md"
             onClick={() => handlePreview()}
           >
             Preview
           </Button>
          }
          </FormGroup>
          <FormGroup>
            <Label
              className="mt-3"
              style={{ marginRight: "1em" }}
              for="bulkUpload"
            >
              View Bulk Format :
            </Label>
            <span className="">
              <WhisperTooltip
                placement="top"
                trigger="hover"
                tooltipMsg="View Bulk Format"
              >
                <a
                  target="_blank"
                  href={
                    RouteForShow + "notice_bulk/" + props?.request?.notice_bulk
                  }
                >
                  <IconButton
                    size="sm"
                    appearance="primary"
                    color="green"
                    icon={<FileDownloadIcon />}
                  />
                </a>
              </WhisperTooltip>
            </span>
          </FormGroup>
          {!props?.existData && (
            <>
              <p className="mt-4" style={{ color: "#ff0a16" }}>
                Note : - For adding / deleting variable ( ex. --some_name-- )
                please make changes in above editor and also upload new csv
                below for the same.
              </p>
              <FormGroup>
                <Label className="mt-3" for="bulkUpload">
                  Upload Format for Bulk Upload :
                </Label>
                <FileUploader
                  name="file"
                  types={["csv"]}
                  onTypeError={e =>
                    e &&
                    props?.setRequest({
                      ...props?.request,
                      bulk_csv: null,
                    })
                  }
                  handleChange={e =>
                    props?.setRequest({
                      ...props?.request,
                      bulk_csv: e,
                    })
                  }
                />
              </FormGroup>
            </>
          )}
          <FormGroup className="mt-3">
            <Label for="linkExpire">Will Reply Link Expire ? :</Label>
            <samp className="px-3">
              <Input
                style={{ marginRight: "0.5em" }}
                name="linkExpire"
                type="radio"
                checked={props?.request?.willLinkExpire == 1}
                onChange={() => handleLinkExpireChange(1)}
              />
              <Label check>Yes</Label>
            </samp>
            <span>
              <Input
                style={{ marginRight: "0.5em" }}
                name="linkExpire"
                type="radio"
                checked={props?.request?.willLinkExpire == 0}
                onChange={() => handleLinkExpireChange(0)}
              />
              <Label check>No</Label>
            </span>
            {/* <Input type="select" name="linkExpire" id="linkExpire">
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </Input> */}
          </FormGroup>
          <FormGroup className="mt-3">
            <Label for="sendAs">Send As :</Label>
            <samp className="px-3">
              <Input
                style={{ marginRight: "0.5em", cursor: "pointer" }}
                name="sendAs"
                type="radio"
                checked={parseInt(props?.request?.send_as) === 1}
                onChange={() => handleSendAsChange(1)}
              />
              <Label check>Legal Notice</Label>
            </samp>
            <span>
              <Input
                style={{ marginRight: "0.5em", cursor: "pointer" }}
                name="sendAs"
                type="radio"
                checked={parseInt(props?.request?.send_as) === 2}
                onChange={() => handleSendAsChange(2)}
              />
              <Label check>Communication</Label>
            </span>
          </FormGroup>
          <FormGroup className="mt-3" inline>
            <Label for="communicationModes">Communication Modes:</Label>
            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "20px" }}>
                <Input
                  type="checkbox"
                  id="email"
                  checked={parseInt(props?.request?.emailEnabled) === 1}
                  onChange={e =>
                    handleCommunicationModeChange(1, e.target.checked)
                  }
                  style={{ marginRight: "5px" }}
                />
                <Label for="email">Email</Label>
              </div>
              <div style={{ marginRight: "20px" }}>
                <Input
                  type="checkbox"
                  id="sms"
                  checked={parseInt(props?.request?.smsEnabled) === 1}
                  onChange={e =>
                    handleCommunicationModeChange(2, e.target.checked)
                  }
                  style={{ marginRight: "5px" }}
                />
                <Label for="sms">SMS</Label>
              </div>
              <div style={{ marginRight: "20px" }}>
                <Input
                  type="checkbox"
                  id="whatsapp"
                  checked={parseInt(props?.request?.whatsappEnabled) === 1}
                  onChange={e =>
                    handleCommunicationModeChange(3, e.target.checked)
                  }
                  style={{ marginRight: "5px" }}
                />
                <Label for="whatsapp">WhatsApp</Label>
              </div>
              <div>
                <Input
                  type="checkbox"
                  id="rpad"
                  checked={parseInt(props?.request?.rpadEnabled) === 1}
                  onChange={e =>
                    handleCommunicationModeChange(4, e.target.checked)
                  }
                  style={{ marginRight: "5px" }}
                />
                <Label for="rpad">RPAD</Label>
              </div>
            </div>
          </FormGroup>
          <FormGroup className="mt-3">
            <Label for="autoGeneratePdf">
              System generated pdf required? :
            </Label>
            <samp className="px-3">
              <Input
                style={{ marginRight: "0.5em", cursor: "pointer" }}
                name="autoGeneratePdf"
                type="radio"
                checked={parseInt(props?.request?.autoGeneratePdf) === 1}
                onChange={() => handleAutoGeneratePdfChange(1)}
              />
              <Label check>Yes</Label>
            </samp>
            <span>
              <Input
                style={{ marginRight: "0.5em", cursor: "pointer" }}
                name="autoGeneratePdf"
                type="radio"
                checked={parseInt(props?.request?.autoGeneratePdf) === 0}
                onChange={() => handleAutoGeneratePdfChange(0)}
              />
              <Label check>No</Label>
            </span>
            {/* <Input type="select" name="linkExpire" id="linkExpire">
            <option value="">-- Select --</option>
            <option value="yes">Legel Notice</option>
            <option value="no">Communication</option>
          </Input> */}
          </FormGroup>
          <FormGroup>
            <Label for="canvaSubtitle">Subtitle for Canva :</Label>
            <Input
              type="text"
              name="canvaSubtitle"
              id="canvaSubtitle"
              placeholder="Enter the Subtitle for Canva"
              value={props?.request?.canvaSubtitle || ""}
              onChange={e =>
                props?.setRequest({
                  ...props?.request,
                  canvaSubtitle: e.target.value,
                })
              }
            />
          </FormGroup>

          <FormGroup>
            <Label for="enterpriseAbbr">Enterprise Abbr :</Label>
            <Input
              type="text"
              name="enterpriseAbbr"
              id="enterpriseAbbr"
              placeholder="Enter Enterprise Abbr"
              value={props?.request?.enterpriseAbbr || ""}
              onChange={e =>
                props?.setRequest({
                  ...props?.request,
                  enterpriseAbbr: e.target.value,
                })
              }
            />
          </FormGroup>
          {/* <FormGroup>
          <Label className="mt-3" for="linkExpire">
            Send As :
          </Label>
          <Input type="select" name="linkExpire" id="linkExpire">
            <option value="">-- Select --</option>
            <option value="yes">Legel Notice</option>
            <option value="no">Communication</option>
          </Input>
        </FormGroup> */}
          <Button className="my-4" color="primary" size="md" type="submit">
            Submit
          </Button>{" "}
        </Form>
      </div>
    )
  )
}

const mapStateToProps = state => ({
  token: state.Login.token,
})

export default connect(mapStateToProps)(NoticeForm)
