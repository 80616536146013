import React, { useEffect, useState } from "react"
import {
  Button,
  Checkbox,
  CustomProvider,
  IconButton,
  Pagination,
} from "rsuite"
import VisibleIcon from "@rsuite/icons/Visible"
import CheckRoundIcon from "@rsuite/icons/CheckRound"
import SendIcon from "@rsuite/icons/Send"
import AddOutlineIcon from "@rsuite/icons/AddOutline"
import ReviewPassIcon from "@rsuite/icons/ReviewPass"
import MoveUpIcon from "@rsuite/icons/MoveUp"
import EventDetailIcon from "@rsuite/icons/EventDetail"
import DetailIcon from "@rsuite/icons/Detail"
import { Link, useHistory, useParams } from "react-router-dom"
import { getDataNotice, getDraftNotice } from "store/actions"
import { connect, useDispatch } from "react-redux"
import WhisperTooltip from "components/Common/Whisper/WhisperTooltip"
import useWindowDimensions from "common/useWindowDimensions"
import { ShowSwal } from "common/ShowSwal"
import { ApiCall } from "common/ActionApiCall/ApiCall"
import { ShowNotification } from "common/ShowNotification"
import AddNotes from "../AddNotes"
import { DownloadApiCall } from "common/ActionApiCall/DownloadApiCall"
import NotesAttachment from "../NotesAttachment"
import { Table } from "reactstrap"
import Loader from "common/Loader"

// Function to check if any reply has an attachment
function parseYourReply(yourReply) {
  try {
      const replyArray = JSON.parse(yourReply);
      return replyArray.some(reply => reply.attachment);
  } catch (error) {
      console.error("Error parsing JSON:", error);
      return false;
  }
}

function NewOverdueTable(props) {
  const [limit, setLimit] = useState(50)
  const [page, setPage] = useState(1)
  const [action, setAction] = useState(false)
  const history = useHistory()

  const dispatch = useDispatch()
  const params = useParams()
  const data = props?.data?.resultData
  let checked = false
  let indeterminate = false
  const { height, width } = useWindowDimensions()

  const handleChangeLimit = dataKey => {
    setPage(1)
    setLimit(dataKey)
  }

  if (data?.length !== 0) {
    if (props.checkedKeys?.length === data?.length) {
      checked = true
      props?.setOpenSubMenu(true)
    } else if (props?.checkedKeys?.length === 0) {
      checked = false
      props?.setOpenSubMenu(false)
    } else if (
      props?.checkedKeys?.length > 0 &&
      props?.checkedKeys?.length < data?.length
    ) {
      indeterminate = true
      props?.setOpenSubMenu(true)
    }
  }

  const handleCheckAll = (value, checked) => {
    const keys = checked ? data?.map(item => item.Id) : []
    props?.setCheckedKeys(keys)
    props?.setOpenSubMenu(checked)
  }

  const handleCheck = (value, checked) => {
    const keys = checked
      ? [...props?.checkedKeys, value]
      : props?.checkedKeys?.filter(item => item !== value)
    props?.setCheckedKeys(keys)
    checked && props?.setOpenSubMenu(checked)
  }

  const downloadClickNoticePdf = file => {
    const request = {
      token: props?.token,
      folder: "userGnrtdNotice",
      filename: file,
    }
    DownloadApiCall(request, "download/single-download", "_blank")
  }

  const handleResolvedAction = value => {
    const request = {
      token: props?.token,
      mnid: params?.id,
      userRowNoticeId: value,
    }
    ShowSwal(
      "Are you sure?",
      "You won't be able to revert this!",
      "warning",
      "Yes, Resolved it!",
      result => {
        if (result.isConfirmed) {
          ApiCall(request, "user/notice-resolve", false, result => {
            if (result?.data?.response === "success") {
              setAction(!action)
              ShowNotification(result?.data?.message, "success")
              history.push("/user/resolved-notice/" + params.id)
            } else {
              ShowNotification(result?.data?.message, "danger")
            }
          })

          // Swal.fire("Deleted!", "Your file has been deleted.", "success")
          // ShowNotification("Deleted! Your file has been deleted.", "success")
        }
      }
    )
  }

  const handleEscaltedAction = value => {
    const request = {
      token: props?.token,
      mnid: params?.id,
      userRowNoticeId: value,
    }
    ShowSwal(
      "Are you sure?",
      "You won't be able to revert this!",
      "warning",
      "Yes, Escalated it!",
      result => {
        if (result.isConfirmed) {
          ApiCall(request, "user/notice-escalate", false, result => {
            if (result?.data?.response === "success") {
              setAction(!action)
              ShowNotification(result?.data?.message, "success")
              history.push("/user/escalated-notice/" + params.id)
            } else {
              ShowNotification(result?.data?.message, "danger")
            }
          })

          // Swal.fire("Deleted!", "Your file has been deleted.", "success")
          // ShowNotification("Deleted! Your file has been deleted.", "success")
        }
      }
    )
  }

  useEffect(() => {
    const displayStart = limit * (page - 1)

    const requestPayload = {
      data_type: "overdue",
      notice_id: params.id,
      user_id: params?.userId ? atob(params?.userId) : "",
      token: props.token,
      page: page,
      iDisplayStart: displayStart,
      iDisplayLength: limit,
      notesAdded: props?.filter?.notesAdded,
      replyAdded: props?.filter?.replyAdded,
      batch: props?.filter?.batch,
      case_ref_id: props?.filter?.case_ref_id,
      undeliveredWhatsapp: props?.filter?.undeliveredWhatsapp,
      undeliveredEmail: props?.filter?.undeliveredEmail,
      undeliveredSms: props?.filter?.undeliveredSms,
      filterFrom: props?.filter?.filterFrom,
      filterTo: props?.filter?.filterTo,
    }
    dispatch(getDataNotice(requestPayload))
  }, [page, limit, action, props?.filter?.submit])

  return (
    <div className="table-responsive" style={{ fontSize: "0.9em" }}>
      <div style={{ height: height - 300, overflow: "scroll" }}>
        <Table size="sm">
          <thead>
            <tr>
              <th>
                <Checkbox
                  inline
                  checked={checked}
                  indeterminate={indeterminate}
                  onChange={handleCheckAll}
                />
              </th>
              <th>Reference ID</th>
              <th>Notice ID</th>
              <th>Date of Generation</th>
              <th>Due Date for Response</th>
              <th>Batch</th>
              {!params?.userId && <th>Notes</th>}
              <th>Details</th>
              {!params?.userId && (
                <>
                  <th>Action</th>
                  <th>Mark As</th>
                </>
              )}
            </tr>
          </thead>
          <tbody>
            {props?.tableLoading ? (
              <tr>
                <td colSpan={10} className="text-center">
                  <div className="mt-4">
                    <Loader />
                  </div>
                </td>
              </tr>
            ) : data?.length !== 0 ? (
              data?.map((rowdata, index) => {
                return (
                  <tr key={index}>
                    <td>
                      {page === 1 ? index + 1 : limit * (page - 1) + index + 1}
                      <Checkbox
                        value={rowdata.Id}
                        inline
                        onChange={handleCheck}
                        checked={props?.checkedKeys?.some(
                          item => item === rowdata.Id
                        )}
                      />
                    </td>
                    <td>{rowdata.Reference_ID}</td>
                    <td>{rowdata.notice_unq_id}</td>
                    <td>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: rowdata.date_of_generation,
                        }}
                      />
                    </td>
                    <td>{rowdata?.due_date_for_response}</td>
                    <td>{rowdata.batchName}</td>
                    {!params?.userId && (
                      <td>
                        <AddNotes
                          id={rowdata.Id}
                          token={props?.token}
                          notice={params?.id}
                          userNotes={rowdata?.userNotes}
                          action={action}
                          setAction={setAction}
                        />
                        {rowdata?.notesAttachment && (
                          <>
                            <NotesAttachment
                              attachment={rowdata?.notesAttachment}
                              id={rowdata.Id}
                              token={props?.token}
                            />
                          </>
                        )}
                      </td>
                    )}
                    <td>
                      <WhisperTooltip
                        placement="top"
                        trigger="hover"
                        tooltipMsg="View Party Details"
                      >
                        <Link
                          to={{
                            pathname: "/user/party-details/" + btoa(rowdata.Id),
                            state: { prevPath: location.pathname },
                          }}
                        >
                          <IconButton
                            size="xs"
                            appearance="primary"
                            color="violet"
                            icon={<DetailIcon />}
                          />
                        </Link>
                      </WhisperTooltip>
                      {rowdata?.your_reply && !params?.userId && (
                        <>
                          &nbsp;
                          <WhisperTooltip
                            placement="top"
                            trigger="hover"
                            tooltipMsg="View Party Reply"
                          >
                            <Link
                              to={{
                                pathname:
                                  "/user/party-reply/" +
                                  rowdata.reply_for_notice_id,
                                state: { prevPath: location.pathname },
                              }}
                            >
                              <IconButton
                                size="xs"
                                appearance="primary"
                                color={parseYourReply(rowdata.your_reply) ? "red" : "yellow"}
                                icon={<SendIcon />}
                              />
                            </Link>
                          </WhisperTooltip>
                        </>
                      )}
                    </td>
                    {!params?.userId && (
                      <>
                        <td>
                          <WhisperTooltip
                            placement="top"
                            trigger="hover"
                            tooltipMsg="View Track"
                          >
                            <Link
                              target="_blank"
                              to={{
                                pathname:
                                  "/user/track/" +
                                  btoa(params.id + "-" + rowdata.Id),
                                state: { prevPath: location.pathname },
                              }}
                            >
                              <IconButton
                                size="xs"
                                appearance="primary"
                                color="blue"
                                icon={<EventDetailIcon />}
                              />
                            </Link>
                          </WhisperTooltip>
                          &nbsp;
                          <WhisperTooltip
                            placement="top"
                            trigger="hover"
                            tooltipMsg="View Notice PDF"
                          >
                            <IconButton
                              size="xs"
                              appearance="primary"
                              color="orange"
                              icon={<VisibleIcon />}
                              onClick={() =>
                                downloadClickNoticePdf(
                                  rowdata?.user_notice_path
                                )
                              }
                            />
                          </WhisperTooltip>
                        </td>
                        <td>
                          <WhisperTooltip
                            placement="top"
                            trigger="hover"
                            tooltipMsg="Resolved"
                          >
                            <IconButton
                              size="xs"
                              appearance="primary"
                              color="green"
                              icon={<ReviewPassIcon />}
                              onClick={() => handleResolvedAction(rowdata.Id)}
                            />
                          </WhisperTooltip>
                          &nbsp;
                          <WhisperTooltip
                            placement="top"
                            trigger="hover"
                            tooltipMsg="Escalated"
                          >
                            <IconButton
                              size="xs"
                              appearance="primary"
                              color="red"
                              icon={<MoveUpIcon />}
                              onClick={() => handleEscaltedAction(rowdata.Id)}
                            />
                          </WhisperTooltip>
                        </td>
                      </>
                    )}
                  </tr>
                )
              })
            ) : (
              <tr>
                <td colSpan={10} className="text-center">
                  <div className="mt-4">No Data Found</div>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
      <Pagination
        className="my-4"
        prev
        next
        first
        last
        ellipsis
        boundaryLinks
        maxButtons={5}
        size="xs"
        layout={["total", "-", "limit", "|", "pager", "skip"]}
        total={props?.data?.overdueCount}
        limitOptions={[50, 100, 500, 1000, 5000, 10000]}
        limit={limit}
        activePage={page}
        onChangePage={setPage}
        onChangeLimit={handleChangeLimit}
      />
    </div>
  )
}

const mapStateToProps = state => ({
  data: state.DraftNotice.items,
  tableLoading: state.DraftNotice.loading,
  token: state.Login.token,
})

export default connect(mapStateToProps)(NewOverdueTable)
