import PropTypes from "prop-types"
import React, { useEffect, useRef } from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"

//i18n
// import { withTranslation } from "react-i18next";

const SidebarContent = props => {
  const ref = useRef()
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    var pathpart = props.location.pathname
    var pathName = pathpart.split("/")
    pathName = "/" + pathName.slice(1, 3).join("/")

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }

  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">Main </li>
            <li>
              <Link to="/user/dashboard" className="waves-effect">
                <i className="mdi mdi-view-dashboard"></i>
                <span>Dashboard</span>
              </Link>
            </li>

            <li>
              <Link to="/user/noticelist" className="waves-effect">
                <i className="mdi mdi-clipboard-list"></i>
                <span>All Notices</span>
              </Link>
            </li>

            <li>
              <Link to="/user/report" className="waves-effect">
                <i className="mdi mdi-file-document-multiple"></i>
                <span>CSV Report</span>
              </Link>
            </li>
            <li>
              <Link to="/user/refidsearch" className="waves-effect">
                <i className="mdi mdi-file"></i>
                <span>Ref. Id Search</span>
              </Link>
            </li>
            <li>
              <Link to="/user/filesharing" className="waves-effect">
                <i className="mdi mdi-share-all"></i>
                <span>File Sharing</span>
              </Link>
            </li>
            <li>
              <Link to="/user/letterhead" className="waves-effect">
                <i className="mdi mdi-file"></i>
                <span>Letterhead</span>
              </Link>
            </li>
            <li>
              <Link to="/user/subuser" className="waves-effect">
                <i className="mdi mdi-account-supervisor"></i>
                <span>Sub User</span>
              </Link>
            </li>

            {/* <li>
							<Link to="/#" className="has-arrow waves-effect">
								<i className="mdi mdi-email-outline"></i>
								<span>Email</span>
							</Link>
							<ul className="sub-menu" aria-expanded="false">
								<li>
									<Link to="/email-inbox">Inbox</Link>
								</li>
								<li>
									<Link to="/email-read">Email Read </Link>
								</li>
								<li>
									<Link to="/email-compose">Email Compose </Link>
								</li>
							</ul>
						</li> */}

            {/* <li className="menu-title">Components</li>
						<li>
							<Link to="/#" className="has-arrow waves-effect">
								<i className="mdi mdi-buffer"></i>
								<span>UI Elements</span>
							</Link>
							<ul className="sub-menu" aria-expanded="false">
								<li>
									<Link to="/ui-alerts">Alerts</Link>
								</li>
							</ul>
						</li>

						<li className="menu-title">Extras</li> */}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(SidebarContent)
